.header-search {

    .input-box {
        margin: 0;
        background-color: @white;

        label {
            .screen-readers();
        }
    }

    .search-button {
        background-color: transparent;
        padding: 0;
        display: flex;
        justify-content: center;
        align-items: center;

        .icon {
            height: 14px;
            min-width: 14px;
            stroke: @black;
        }
    }

    &.search-hidden {
        max-width: 36px;
        overflow: hidden;

        .input-text {
            border: 0;
        }
    }
}

.maxW(@tablet-max, {
        .header-search {
            top: @menu-height;
            height: @search-height;

            .translate-hide();

            .menu-open & {
                .translate-show();
            }

            input {
                border: 0;
            }

            .search-button {
                right: 0;
                width: 44px;
            }

            .minisearch {
                background-color: @navy;
            }

            .input-box {
                background: transparent;
                text-align: center;
                padding-top: 1rem;
                padding-bottom: 1rem;
                max-width: 280px;
                margin: auto;

                .input-text {
                    color: @white;
                    border: 1px solid @white;
                    border-radius: 30px;

                    &::placeholder {
                        color: @white;
                    }
                }
            }

            .search-button {
                .icon {
                    stroke: @white;
                }
            }
        }
    }

);

.minW(@mobile-max, {

        .header-search {

            input {
                padding-left: @padding-tablet;
                padding-right: @padding-tablet;
            }
        }

    }

);

.minW(@desktop-min, {
        .header-search {
            position: absolute;
            left: auto;
            right: 320px;
            background-color: transparent;
            z-index: 50;

            .minisearch {
                direction: rtl;

                > * {
                    direction: ltr;
                }
            }

            .search-button {
                position: relative;
                padding: 0 8px;
                opacity: 1;

                .icon {
                    height: auto;
                }
            }

            &:hover, &:focus, &:active, .active {
                .input-box {
                    width: 200px;
                    padding-right: 0;
                }
            }

            .input-box {
                .button-navy-outline();
                .nav-button-link();
                .transition(all .2s);
                width: 50px;

                input {
                    border-bottom: 0;
                    padding: 0;
                    height: auto;
                    min-height: 20px;
                    font-family: @font-2;
                    font-size: 16px;
                    color: @navy;

                    &::placeholder {
                        font-family: @font-2;
                        font-size: 16px;
                        color: @navy;
                    }
                }
            }

            .input-text {
                color: @black;
            }

            .menu-open &,
            .minicart-open &,
            .account-open & {
                transform: none;
                .link-hidden;
                z-index: -1;
            }

            .searchautocomplete-loader {
                right: 1.7rem;
            }
        }
    }

);

.minW(@desktop-sm, {
        .header-search {
            right: 390px;
        }
    }

);

.minW(@desktop-md, {
        .header-search .input-box {
            width: 200px;
            padding-right: 0;
        }
    }

);


.minW(1440px, {
        .header-search {
            right: 420px;
        }
    }

);

.minW(@desktop-lg, {
        .header-search {
            right: 465px;

            .cms-home & {
                right: 530px;
            }

            &:hover, &:focus, &:active, .active {
                .input-box {
                    width: 225px;
                }
            }

            .search-button {
                right: 8px;
            }

            .input-box {
                .button-navy-outline();
                .nav-button-link();
                .transition(all .2s);
                width: 50px;

                input {
                    font-size: 2rem;

                    &::placeholder {
                        font-size: 2rem;
                    }
                }
            }

        }
    }

);