.header-account {
    .translate-hide();
    .container;
    top: 0;
    background-color: @white;
    left: auto;
    z-index: -1;
    overflow: auto;

    &.logged-in.not-loyal-customer {
        height: auto;

        &:before {
            content: none !important;
        }

        .account-top-klub {
            display: none;
        }

        .links {
            position: static;
            border-top: 0;
            padding: 0;
        }

        .links__row {
            display: flex;
            flex-direction: column;
            gap: @padding-mobile;
        }

        .links__item {
            .button-dark;
        }

        .link-reorder {
            order: 0;
        }

        .link-account {
            order: 1;
        }

        .link-logout {
            .button-navy-outline;
            order: 2;
        }

        .maxW(@mobile-md - 1, {
                .links__item {
                    margin: auto;
                }
            }

        );

        .minW(@mobile-md, {
                .links {
                    max-width: 330px;
                    margin-left: auto;
                }

                .links__row {
                    display: grid;
                    grid-template-columns: repeat(2, 1fr);
                    grid-template-rows: repeat(2, 1fr);
                }

                .link-reorder {
                    margin-left: auto;
                    grid-row: 1;
                    grid-column: 2;
                }

                .link-account {
                    margin-right: auto;
                    grid-row: 2;
                    grid-column: 1;
                }

                .link-logout {
                    margin-left: auto;
                    grid-row: 2;
                    grid-column: 2;
                }
            }

        );

        .minW(@desktop-min, {
                width: calc(~"@{main-search-width} - @{padding-mobile}*2");
                max-width: @main-search-width-lg;
                top: @menu-height;
                right: @padding-mobile;
                border-radius: @radius;

                .links__row {
                    margin: auto;
                }
            }

        );

        .minW(@desktop-lg, {
                top: @menu-height-lg;
                width: calc(~"@{main-search-width-lg} - @{padding-mobile}*2 - 110px");
                right: calc(~"@{padding-mobile} + 110px");
            }

        );
    }

    .account-open & {
        .translate-show();
        position: fixed;
        box-shadow: 0 10px 45px -20px rgba(96, 96, 96, 0.20);
        z-index: 10;

        .minW(@desktop-min, {
                position: absolute;

                &:before {
                    content: '';
                    z-index: 1;
                    position: relative;
                    display: flex;
                    align-items: center;
                    border-bottom: 1px solid @border-light;
                    height: @menu-height;
                    margin-left: -@padding-mobile;
                    margin-right: -@padding-mobile;
                    top: -@padding-mobile;
                    color: @red;
                    font-weight: 700;
                    padding-left: @padding-mobile;
                }

                &.logged-in {
                    &:before {
                        content: attr(data-label);
                    }
                }
            }

        );

        .minW(@desktop-lg, {
                &:before {
                    height: @menu-height-lg;
                }

            }

        );
    }

    .account-menu-content {
        position: relative;
        height: calc(100% - 160px);
        overflow: auto;
    }

    .block-customer-login {
        padding-top: @padding-mobile;

        .buttons {
            text-align: right;
            padding-bottom: @padding-mobile;
        }
    }

    .fieldset:last-child {
        margin-bottom: 0;
    }

    .buttons-inline {
        display: flex;
        align-items: center;
        justify-content: space-between;
        line-height: 1.2;
        padding-bottom: 1rem;
        margin-bottom: 1rem;
        font-size: 1.4rem;
        color: @black;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);

        +.buttons-inline {
            border: 0;
            margin-bottom: 0;
            padding-bottom: 0;
        }

        .remind {
            .content-link();
        }
    }

    .create-account {
        .button-navy-outline();
    }

    .links {
        position: fixed;
        right: 0;
        bottom: 0;
        width: 100%;
        top: auto;
        padding: 2rem @padding-mobile;
        border-top: 1px solid @border-light;
        background-color: @white;
        z-index: 100;

        .minW(@tablet-max, {
                bottom: 55px;
            }

        );

        .links__row {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        .account-link {
            font-weight: 700;
            display: flex;
            align-items: center;
            line-height: 1;

            .label {
                margin-right: @padding-mini;
            }

            .icon {
                stroke: @navy;
            }
        }

        .link-logout {
            color: @text-2;
        }
    }

    .account-top-klub {
        padding: @padding-mobile 0;
        position: relative;
        height: 100%;

        &.loading {
            &:before {
                content: '';
                display: block;
                .loading-spinner();
            }

            &:after {
                content: '';
                position: absolute;
                top: calc(40% - 25px);
                left: calc(50% - 25px);
                width: 50px;
                height: 50px;
                border: 5px solid rgba(125, 125, 125, .6);
                border-top-color: #fff;
                border-radius: 50%;
                -webkit-animation: loading 1s ease infinite;
                animation: loading 1s ease infinite;
                z-index: 1000;
            }
        }

        .top-klub-offer-summary {
            margin-top: 0;
        }

        .row-summary {
            align-items: stretch;
            margin-top: 0;
            margin-bottom: @padding-tablet;
            padding-bottom: @padding-tablet;
            border-bottom: 1px solid @border-light;

            .maxW(@ipad-v, {
                    flex-direction: column;
                    align-items: center;
                }

            );
        }

        .row-products {
            width: 100%;
            margin: @padding-mobile auto;
            padding: @padding-tablet 0;
            gap: 0;

            .product-items {
                margin: auto;
                width: auto;
            }

            .maxW(@tablet-max, {

                    .top-klub-offer-products .products-grid {
                        padding-top: 0;
                    }
                }

            );

        }

        .top-klub-button {
            display: flex;
            align-items: flex-end;

            .button {
                .button-small();
            }
        }

        .top-klub-logo {
            width: 180px;

            .maxW(@mobile-max, {
                    order: -1;
                }

            );


        }

        .top-klub-offer-products {
            .products-title {
                width: 100%;
            }
        }
    }
}

.maxW(@tablet-max, {

        .header-account {
            top: @menu-height;

            .buttons-inline {
                flex-direction: column;
                margin-top: @padding-mobile;

                .button, .button-black, .create-account {
                    margin: 1rem auto 0;
                    text-align: center;
                }
            }

            .account-open & {
                width: 100%;
                height: 100%;
                border-top: 1px solid @border-light;
                bottom: 0;
                overflow: auto;
                z-index: 101;
            }
        }

    }

);


.maxW(@ipad-v - 1, {
        .header-account {
            top: @mobile-header-height;
        }
    }

);


.minW(@desktop-min, {

        .skip-account {
            .button-navy-outline();
            .nav-button-link();
            right: 140px;

            .cms-home {
                right: 150px;
            }

            .menu-open & {
                .link-hidden;
            }
        }

        .header-account {
            transform: translateX(20px);
            opacity: 0;
            max-height: 0;
            right: 0;
            left: auto;
            top: -100%;
            width: 880px;
            padding: @padding-mobile;

            &.logged-out {
                width: @main-search-width;
                right: @padding-mobile;
                max-width: @main-search-width-lg;
            }

            input {
                display: none;
            }

            .account-open & {
                transform: translateX(0);
                top: 0;
                opacity: 1;
                max-height: 100vh;
                height: 100vh;

                input {
                    display: inherit;
                }
            }
        }

        .account-open {
            .header-search, .header-minicart {
                .link-hidden;
            }
        }
    }

);

.minW(1100px, {
        .skip-account {
            .cms-home & {
                right: 220px;
            }
        }
    }

);

.minW(@desktop-sm, {
        .skip-account {
            right: 210px;

            .label {
                display: flex;
                color: @navy;
            }
        }
    }

);

.minW(1440px, {
        .skip-account {
            right: 240px;
        }
    }

);

.minW(@desktop-lg, {
        .header-account {
            &.logged-out {
                width: @main-search-width-lg;
            }
        }

        .skip-account {
            right: 260px;
            max-width: 170px;

            .cms-home & {
                right: calc(~"@{minicart-width} - 5rem");
            }
        }
    }

);

.minW(@desktop-lg + 120px, {
        .skip-account {

            .label:not(.logged-in) {
                height: calc(~"@{menu-height-lg} - 1rem");
                max-height: @menu-height-lg;
            }
        }
    }

);