.popup-container {
    position: relative;
    width: auto;
    max-width: 700px;
    margin: @padding-mobile auto;
    color: @text-2;

    form {
        .clearfix;
    }

    button[type='submit'] {
        float: right;
    }
}

.popup-header {
    font-size: 1.6rem;
    font-weight: 700;
    color: @white;
    padding-bottom: @padding-mobile;

    .minW(@mobile-max, {
            font-size: 2.3rem;
        }

    );

    .minW(@desktop-lg, {
            font-size: 3rem;
        }

    );
}

.popup-content {
    background-color: @white;
    padding: 2rem @padding-mobile;
    border-radius: 10px;
    font-size: 1.4rem;

    p {
        margin-top: 0;
    }

    .minW(@tablet-md, {
            padding: @padding-tablet;
        }

    );
}

.popup-footer {
    text-align: right;
    .clearfix;
}

.popup-newsletter,
.popup-consultation {
    .form-row {
        margin-bottom: 0;

        +.form-row {
            margin-top: 0;
        }
    }
}

.subscribe-message {
    display: flex;
    align-items: center;

    .icon {
        display: none;
        margin-right: 2rem;
    }

    .icon-error {
        max-height: 28px;
        max-width: 28px;
    }

    &.success {
        font-size: 1.8rem;
        line-height: 1.3;

        .icon-success {
            display: inline-block;
            stroke: @success;
        }

        +form {
            display: none;
        }
    }

    &.error {
        .icon-error {
            display: inline-block;
            stroke: @error;
        }
    }
}

.popup-advanced-search {
    label {
        .screen-readers;
    }
}

.popup-consultation {
    form {
        margin-top: @padding-mobile;
    }

    .button-location {
        margin-left: auto;
        display: block;
    }

    .select2-container {
        width: 100%;

    }
}

.minW(@mobile-max, {
        .popup-consultation {
            .button-location {
                .align-v();
                right: 0;
                margin-top: 0;
            }

            .select2-container {
                flex: 3;
            }
        }

        .popup-advanced-search {
            .form-columns-2 {
                display: flex;
                align-items: flex-end;
                justify-content: space-between;

                .input-box {
                    width: calc(~"50% - @{padding-mobile}");
                    margin-top: 0;
                }
            }
        }

        .popup-newsletter,
        .popup-consultation {
            .checkbox-styled {
                padding-bottom: 2rem;
            }
        }
    }

);

// magnific popup overrides

.mfp-wrap {
    .mfp-close {
        opacity: 1;
        text-align: right;
        font-family: @font-1;
        font-weight: 300;
        font-size: 4rem;
        line-height: 0;
        transition: color @transition-short, transform @transition-short;
        width: 30px;
        height: 44px;
        transform-origin: 50% 50%;

        &:hover {
            transform: scale(1.2);
        }
    }

}

.mfp-image-holder {
    .mfp-close {
        height: 30px;
    }
}

.mfp-close-btn-in {
    .mfp-close {
        color: @white;

        .icon {
            height: 100%;
            width: auto;
        }

        &:hover {
            color: @yellow-dark;
        }
    }
}

// .mfp-arrow-right {
// 	transition: border @transition-short;
// 	&:hover {
// 		&:after{
// 			border-left-color: @yellow-dark;
// 		}
// 	}
// }

// .mfp-arrow-left {
// 	transition: border @transition-short;
// 	&:hover {
// 		&:after{
// 			border-right-color: @yellow-dark;
// 		}
// 	}
// }

.mfp-zoom-in {

    /* start state */
    .mfp-with-anim {
        opacity: 0;
        transition: all 0.2s ease-in-out;
        transform: scale(0.8);
    }

    &.mfp-bg {
        opacity: 0;
        transition: all 0.3s ease-out;
    }

    .mfp-figure,
    .mfp-arrow {
        opacity: 0;
        transition: opacity 0.3s ease-out;
    }

    /* animate in */
    &.mfp-ready {
        .mfp-with-anim {
            opacity: 1;
            transform: scale(1);
        }

        &.mfp-bg {
            opacity: 0.9;
        }

        .mfp-figure,
        .mfp-arrow {
            opacity: 1;
        }
    }

    /* animate out */
    &.mfp-removing {

        .mfp-with-anim {
            transform: scale(0.8);
            opacity: 0;
        }

        &.mfp-bg {
            opacity: 0;
        }

        .mfp-figure,
        .mfp-arrow {
            opacity: 0;
        }
    }
}

.mfp-content {
    .remodal {
        background-color: transparent;
        padding: 0;
        margin: auto;
    }

    .remodal-is-initialized {
        display: block;
    }
}

.modal-promotion-open {
    .mfp-bg {
        background-color: @yellow;
    }
}

.popup-promotion {
    max-width: 930px;

    &:before {
        content: '';
        .icon-promotion;
        .align-h;
        top: 14px;
    }

    .price-single {
        width: 240px;
        margin: auto;

        .price-box {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        .price {
            color: @red;
            font-size: 1.8rem;
            font-weight: 900;
        }

        .price-label {
            color: @text-3;
            font-size: 1.2rem;
            margin-top: .5rem;
        }
    }

    .mfp-close {
        top: 50px;
        position: absolute;
        color: @text-1;
        right: 12px;
        font-size: 32px;
    }

    .popup-header {
        min-height: 50px;
    }

    .promotion-header {
        text-align: center;

        p {
            font-size: 1.8rem;
        }
    }

    .promotion-text {
        text-align: center;
    }

    .products-grid {
        margin-left: 3rem;
        margin-right: 3rem;

        .item,
        .product-item {
            border: 0;

            &:hover {
                box-shadow: none;
            }

            .product-name,
            .product-item-name {
                font-size: 16px;
                text-align: center;

                a {
                    height: 28px;
                }
            }

            .product-image,
            .product-item-photo {
                margin-top: 0;
                padding-top: 0;
            }

            .product-info,
            .product-item-details {
                padding: 0;
            }

            .regular-price {
                .price {
                    text-decoration: line-through;
                    color: @text-3;
                    font-weight: 300;
                }
            }

            .special-price {
                .price {
                    font-size: 18px !important;
                }
            }
        }
    }

    .buttons {
        text-align: center;
        margin-top: 2rem;
    }

    .brand {
        height: 45px;
        margin-top: 2.5rem;

        img {
            display: block;
            margin: 0 auto;
        }
    }

    .h2 {
        margin-top: 0.37em;
    }

    .price-placeholder-container {
        visibility: visible;
    }

    .minW(1500px, {
            .products-grid {
                .item, .product-item {
                    margin: @padding-mobile;
                    width: calc(~"33% - @{padding-mobile} * 2");
                }
            }
        }

    );

    .minW(1750px, {
            .products-grid {
                .item, .product-item {
                    margin: @padding-mobile;
                    width: calc(~"33% - @{padding-mobile} * 2");
                }
            }
        }

    );

}

.popup-tpms {
    .popup-content {
        padding-bottom: 5rem;
    }
}