@image-size: 7rem;

.checkout-cart-index {
    .page-main .columns {
        max-width: @desktop-sm;
    }
}

.cart-container {

    .block-content {
        border-radius: 0;
        box-shadow: @box-shadow-flat;

        &:before {
            border-radius: 0;
            box-shadow: @box-shadow-flat-before;
        }
    }

    .form-cart {

        .product-image-wrapper {
            width: @image-size;
            height: @image-size;
            padding: 3px;
            border: solid 1px @gray;
            float: left;
        }

        .product-item-photo {
            height: auto;
            max-width: @image-size;
        }

        .product-item-name {
            font-size: 1.6rem;
            padding-top: @padding-mini;
            padding-right: @padding-mobile;
        }

        .product-image-container {
            max-width: @image-size;
        }

        .product-item-details {
            padding-left: @image-size + @padding-mobile;
            vertical-align: middle;

            .minW(@ipad-v + 1, {
                    padding-left: @padding-mobile;
                }

            );
        }

        .item-info {
            .control.qty {
                .cart-item-qty-edit(42px);

                label input {
                    .cart-item-qty-edit-input(42px);
                }
            }

            .unit-price {
                .price {
                    color: @text-2;
                    font-size: 1.6rem;
                }
            }

            .subtotal-price {
                .price {
                    color: @navy;
                    font-size: 2rem;
                }
            }
        }

        .action.continue {
            .button-light;

            &:before {
                content: none;
            }

            .icon {
                margin-right: @padding-mobile;
            }

            span {
                line-height: 1.3;
            }
        }

        .action.clear {
            color: @red;

            &:before {
                font-size: 32px;
                line-height: 16px;
                color: inherit;
                content: '\e604';
                font-family: 'icons-blank-theme';
                margin: 0;
                vertical-align: top;
                display: inline-block;
                font-weight: normal;
                overflow: hidden;
                speak: none;
                text-align: center;
            }
        }

        .action.update {
            color: @navy;

            &:before {
                font-size: 46px;
            }
        }

        .action.primary,
        .action-primary {
            .button-dark;

            &.checkout {
                .button-red;
                .button-large();
                display: flex;
                justify-content: center;
                max-width: 240px;
                margin: @padding-mobile auto;
            }
        }

        .action.secondary,
        .action-secondary,
        .action.continue,
        .action.action-delete,
        .action.action-edit {
            .button-navy-outline();

            &:hover,
            &:focus,
            &:active {
                background-color: @navy;
                color: @white;

                span {
                    color: @white;
                }

                .icon {
                    stroke: @white;
                }
            }
        }

        .action.update,
        .action.clear {

            &:hover,
            &:focus,
            &:active {
                border: 0;
            }
        }

        .table-wrapper {
            margin-bottom: @padding-tablet;
        }

        .block-content {
            padding-top: 2rem;
        }

    }

    td.item {
        border: 0;
    }

    th {
        border-bottom: 0;
    }

    .shipping {
        padding-top: 0;

        .block-title {
            strong {
                .h3;
            }
        }
    }

    #block-shipping {
        display: none;
    }

    .cart {
        .pager {
            .toolbar-amount {
                display: none;
            }
        }
    }
}

#block-summary {
    padding: 0;
}

.cart-summary {
    background-color: transparent;
    padding: 0;

    &._sticky {
        position: unset !important;
    }

    .summary.title {
        display: none;
    }

    .block {
        &:first-of-type {
            margin-top: 0;
        }

        .title {
            border: 0;
        }

        &.discount {
            margin-top: 0;
            padding-top: 0;
            padding-bottom: @padding-tablet;
            position: relative;

            .title {
                display: inline-flex;
                padding-left: 0;

                >strong {
                    color: @navy;
                    font-weight: 700 !important;
                }

                &:after {
                    top: 12px;
                    color: @navy;
                    font-weight: 900;
                }
            }
        }
    }

    .checkout-methods-items {
        margin: 0;
        background-color: @white;
        padding: 0 @padding-tablet @padding-tablet;
        display: flex;
        justify-content: flex-end;
        box-shadow: @box-shadow-flat;

        .minW(@ipad-v, {
                >.item {
                    width: 50%;
                }
            }

        );
    }

    .action.primary.checkout {
        .button-red;
        .button-large();
    }

    .actions-toolbar {
        .primary .action {
            .button-dark;
        }
    }

    .title {
        color: @text-1;
    }

    .data.table {
        &.totals {
            border: 0;
            width: 100%;

            th,
            td,
            tr {
                border: 0;
                background-color: @white;
            }

            th {
                color: @text-1;
                line-height: 1.2;
            }

            td {
                color: @text-1;
                font-weight: 900;
            }

            .price {
                color: @navy;
            }

            .mark {
                padding-left: 0;
            }

            .amount {
                padding-right: 0;
            }
        }

        .totals-tax {
            .price {
                font-weight: 500;
            }

            .mark {
                padding-left: 0;
            }
        }
    }

    .cart-totals {
        border: 0;

        .grand.totals.excl {
            display: none;
        }

        .block-content {
            padding-top: 2rem;
        }
    }

    .data.table {
        font-size: 1.6rem;
        line-height: 1.2;

        strong {
            font-weight: 900;
        }

        .price {
            font-size: 2rem;
            font-weight: 900;
        }

        .grand.totals {
            &.incl {
                border-top: 1px solid @border-light;
                margin-top: @padding-mobile;
            }

            th,
            td {
                font-weight: 700;
                vertical-align: bottom;
                padding-top: @padding-tablet;
            }

            .price {
                font-size: 3.2rem;
                color: @red;
            }
        }

        .totals-tax {

            th,
            td {
                padding-bottom: @padding-tablet;
            }
        }
    }

    .discount {
        .coupon {
            display: flex;
            width: 100%;
            justify-content: space-between;
            margin: 0;

            .field {
                flex-grow: 1;
                margin: 0 @padding-mobile 0 0;

                input {
                    border: none;
                    background-color: @main-bg;
                }
            }
        }

        .title {
            border-top: 1px solid @border-light;
            width: 100%;

            &:after {
                position: static;
                margin-top: 6px;
            }
        }

        &:before,
        &:after {
            content: '';
            position: absolute;
            box-shadow: @box-shadow-flat;
            height: 100%;
            top: 0;
            width: 10px;
            z-index: -1;
        }

        &:before {
            left: 0;
        }

        &:after {
            right: 0;
        }
    }
}

.cart-import {
    form {
        margin-top: 15px;
        display: flex;
        align-items: center;
    }
}

#shopping-cart-table {
    border: 0;
    color: @text-1;

    .item-actions {
        td {
            padding: 0 0 2rem;
        }
    }

    .actions-toolbar {
        display: flex;
        justify-content: flex-start;
    }

    thead {
        display: none;
    }

    tr,
    td,
    .item {
        border: 0;

        &:not(.message) {
            padding: @padding-mobile 0;
        }
    }

    .qty {
        padding-left: @padding-mini;
        padding-right: @padding-mini;
    }

    .item-actions {
        border-bottom: 1px solid @border-light;
    }

    .maxW(@mobile-max, {
            .actions-toolbar {
                justify-content: center;
            }
        }

    );

    .maxW(@ipad-v, {
            .item-info {
                display: flex;
                flex-direction: column;
                align-items: center;

                .col {
                    &.unit-price {
                        &:before {
                            content: attr(data-th) ':';
                            display: block;
                            font-weight: 700;

                        }
                    }

                    &.subtotal, &.qty, &.unit-price, &.item {
                        width: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;

                        &:before {
                            padding-bottom: 0;
                            text-align: left;
                        }
                    }

                    &.item {
                        min-height: 90px;
                    }

                    &.qty {
                        .qty {
                            padding: 0;
                        }
                    }
                }
            }
        }

    );
}

.minW(@mobile-max, {
        .cart-totals {
            .table-wrapper {
                margin-top: 0;
                font-size: 1.2rem;
            }
        }
    }

);

.maxW(@mobile-max, {
        .cart.table-wrapper .item-info {
            .col:before {
                white-space: normal;
                font-weight: 500;
            }
        }
    }

);

.maxW(@tablet-max, {

        .abs-shopping-cart-items-desktop,
        .block-cart-failed,
        .cart-container .form-cart,
        .cart-container .cart-gift-item,
        .cart-summary {
            width: 100%;
            float: none;
        }

        .cart-container {
            display: flex;
            flex-direction: column-reverse;
        }
    }

);

.minW(@tablet-max + 1, {

        .abs-shopping-cart-items-desktop,
        .block-cart-failed,
        .cart-container .form-cart,
        .cart-container .cart-gift-item {
            width: calc(~"63% - @{padding-mobile}");
        }

        .cart-summary {
            width: calc(~"37% - @{padding-mobile}");
        }
    }

);