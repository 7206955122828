.checkout {
    padding-top: 0;

    .btn-cart {
        .button-large();
        display: block;
        width: 100%;
        text-align: center;
    }

    .input-box {
        &:not(.checkbox-styled) {
            flex-direction: column;

            label {
                width: 100%;
            }

            .input-text {
                width: 100%;
                margin-top: @padding-mobile;
            }
        }

        &.checkbox-styled {
            label {
                font-family: @font-1;
                font-weight: 400;
                color: @text-2;
            }
        }
    }

    .input-description {
        color: @text-6;
        font-family: @font-1;
        font-weight: normal;
        font-size: 1.2rem;
    }

    .block-content {

        +.block-title,
        +.block-content {
            margin-top: @padding-tablet;
        }
    }

    .validation-advice {
        width: 100%;
    }

    .checkout-box {
        border-bottom: 1px solid @rgba-black;
        margin-bottom: @padding-tablet;
    }

    .price-row {
        display: flex;
        justify-content: space-between;
        line-height: 1;
        padding-bottom: @padding-mobile;
        margin-bottom: @padding-mobile;
    }

    .price-label {
        color: @text-2;
        font-size: 1.6rem;
    }

    .price {
        color: @text-1;
        font-size: 2rem;
        font-weight: 900;
        text-align: right;
    }

    .coupon-code {
        .checkout__accordion-title;
    }

    .navigate-button {
        &.disabled {
            pointer-events: none;
            background-color: @gray;
            border-color: @gray;
            color: @text-2;
        }
    }
}

.checkout__complete {
    text-align: center;

    .buttons {
        margin-top: @padding-tablet;
    }
}

.checkout__complete__heading {
    .h1;
}

.checkout__complete__number {
    .h3;
}

.checkout-grid--reversed {
    display: flex;
    flex-direction: column-reverse;
}

.error-msg {
    color: @error;
}

.maxW(@desktop-min, {
        .checkout-col + .checkout-col {
            margin-top: @padding-tablet;
        }

        .checkout-grid--reversed {
            .checkout-col + .checkout-col {
                margin-bottom: @padding-tablet;
            }
        }
    }

);

.onestepcheckout-shipping-method {
    .buttons {
        margin-bottom: @padding-mobile;
    }
}

#shipping_address .buttons {
    margin-top: @padding-tablet;
}

.checkout__product-index,
.checkout__product-remove {
    display: block;
    color: @text-2;
    font-size: 12px;
    float: left;
    font-weight: 300;
}

.removeqty {
    padding-right: 1rem;

    &:before {
        content: '';
        .icon('bin');
        background-position: 0 center;
        background-size: auto;
        width: 2rem;
        height: 1.25rem;
        display: inline-block;
    }
}

.checkout__product-image {
    padding: 3px;
    width: 70px;
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: solid 1px @gray;
    float: left;
    margin-right: @padding-mobile;
    margin-bottom: @padding-mobile;
}

.onestepcheckout-coupons {
    .container;
    margin-top: @padding-tablet;
    padding-top: @padding-tablet;
    padding-bottom: @padding-tablet;
    background-color: @white;
    border-radius: @radius;
}

.checkout__accordion-title {
    color: @text-2;
    font-size: 1.6rem;
    position: relative;
    cursor: pointer;

    .icon {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translate(0, -50%) rotate(-90deg);
    }

    .checkout__accordion--expanded & {
        .icon {
            transform: translate(0, -50%) rotate(90deg);
        }
    }
}

.checkout__accordion-item {
    .checkout__accordion-content {
        display: none;
    }

    &.checkout__accordion--expanded {
        .checkout__accordion-content {
            display: block;
        }
    }
}

.checkout__accordion-content {
    font-family: @font-1;
}

.checkout-description {
    text-align: center;
    color: @text-6;
}

.checkout__summary {

    .addsqty,
    .subsqty,
    .removeqty,
    .onestepcheckout-totals-col {
        display: none !important;
    }

    .qty {
        input {
            pointer-events: none;
            font-size: 2rem;
            width: 100%;
        }

        &:before {
            content: 'x';
        }
    }

    .onestepcheckout-summary-col {
        margin-bottom: @padding-tablet;
    }

    .checkout-agreements {
        .validation-advice {
            bottom: 0;
        }
    }
}

.checkout {
    .btn-cart {
        .button-large();
        display: block;
        width: 100%;
        text-align: center;
        text-decoration: none;
    }
}

.onestepcheckout-index-index {

    .onestepcheckout-description {
        margin-bottom: @padding-mobile;
    }

    .col1-layout {
        .main {
            max-width: @desktop-md;
        }
    }

    ul,
    li {
        .list-reset;
    }

    .select2-results__option {
        padding: 6px 2rem;
    }

    .shipment-methods {
        .checkbox-styled;
        padding-top: 0;
        padding-bottom: 0;

        label {
            margin-bottom: @padding-mini;
            line-height: 1.3;
        }
    }

    .onestepcheckout-shipping-method-block {
        padding-bottom: @padding-mobile;
    }

    .input-different-shipping {
        .checkbox-styled;

        label {
            &:before {
                top: -2px;
            }
        }
    }

    .input-first-name {
        margin-top: 0;
    }

    #checkout-payment-method-load {
        .checkbox-styled;
        padding-top: 0;
        margin: 0;
    }

    // .input-region,
    .input-group {
        flex-direction: column;
        align-items: flex-start;

        .input-text,
        .nice-select {
            &:not(:last-child) {
                margin-bottom: @padding-mobile;
            }

            &:not(:first-child) {
                margin-top: @padding-mini;
            }
        }
    }

    .osc-short {

        &:last-child {
            &:not(:only-child) {
                margin-bottom: 0;
            }
        }

        &.input-postcode {
            margin-bottom: @padding-mobile;
        }

        .label-annotation {
            display: flex;
            flex-basis: 100%;
            margin-top: 5px;
        }
    }

    .price {
        color: @text-1;
        font-size: 2rem;
        font-weight: 900;
        text-align: right;
    }

    .onestepcheckout-column-left,
    .form-list {

        .input-box {
            margin-top: @padding-mobile;
            margin-bottom: @padding-mobile;

            >.nice-select {
                margin-top: @padding-mobile;

                @media all and (max-width: @mobile-max) {
                    float: none;
                }
            }
        }

        .input-firstname {
            margin-top: 0;
        }

        .address-select {

            &--disabled {
                border-color: @yellow-dark;
                color: @text-1;
                padding: 0;
                pointer-events: none;

                &::after {
                    display: none;
                }
            }
        }
    }

    // .input-different-shipping {
    // 	label {
    // 		font-family: @font-1;
    // 		font-weight: 400;
    // 		color: @text-2;
    // 	}
    // }

    .nice-select {
        &:not(:last-child) {
            margin-bottom: @padding-mobile;
        }
    }

    .shipment-methods {
        dd {
            .h6;
        }
    }
}

.checkout-cart-index {
    .cart-empty {
        .container;
        padding-top: @padding-tablet;
        padding-bottom: @padding-tablet;
        background-color: @white;
        border-radius: @radius;

        a {
            text-decoration: underline;
        }
    }
}

div.loading-ajax,
div.loading-ajax-login,
#loading-process {
    height: 50px;
    width: 50px;
    .align;
    position: fixed;
    text-align: center;
    z-index: 999999;
    display: none;

    .remodal-is-locked & {
        display: block;
    }
}

.minW(@desktop-lg, {
        .checkout-cart-index {
            .cart-empty {
                padding-top: @padding-desktop;
                margin-top: @padding-tablet;
            }
        }
    }

);

.onestepcheckout-place-order {
    .button-red;
}

.onestepcheckout-place-order-wrapper {
    text-align: right;
}

.onestepcheckout-totals {
    border: none;
    width: 100%;

    th,
    td {
        border: none;
        background-color: @white  !important;
    }
}

table.onestepcheckout-summary {
    color: @text-1;
    line-height: 1.2;
    font-weight: 300;
    border: none;
    width: 100%;

    thead {
        display: none;
    }

    th,
    td {
        border: none;
        background-color: @white;
        padding: 0;
    }

    tr {
        min-height: 50px;

        &:not(:last-of-type) {
            border-bottom: 1px solid @gray;
        }

        td {
            padding-top: @padding-mobile;
            padding-bottom: @padding-mobile;
        }
    }

    &:not(table) {
        font-size: 1.4rem;
        margin: 0;
    }

    .name {
        font-size: 1.6rem;
        font-weight: 900;
        padding-right: 1rem;
    }

    .qty {
        font-weight: bold;
        font-size: 1.6rem;
    }

    .qty {
        text-align: center;
        font-weight: 700;
        max-width: 50px;

        .qtyinput {
            border: none;
        }
    }

    .price {
        display: block;
        text-align: right;
        color: @text-1;
        padding-left: 1rem;
    }

    .editcart {
        text-align: center;
        font-size: 2.2rem;
        font-family: @font-1;
        padding: 0;
        width: 20px;
        justify-content: center;
        align-items: center;

        a {
            text-decoration: none;
            display: block;
            line-height: 0;
            transition: background-color @transition-short;

            &:hover,
            &:focus,
            &:active {
                color: @text-1;
            }
        }
    }
}

.onestepcheckout-totals {
    width: 100%;
    margin-bottom: @padding-tablet;
    color: @text-1;

    .a-right {
        font-size: 1.6rem;
        line-height: 1.2;
        padding-bottom: 1rem;
    }

    tr {
        &:last-child {
            font-size: 2rem;
            border-top: 1px solid @rgba-black;

            td {
                padding-top: @padding-mobile;
                vertical-align: bottom;
            }

            .price {
                font-size: 3.2rem;
                line-height: 1.4;
            }
        }

        td:first-child {
            color: @text-2;
        }
    }

    tr:nth-last-child(2) {
        td {
            padding-bottom: @padding-mobile;
        }
    }

    td {
        &:last-child {
            text-align: right;
        }
    }
}

.onestepcheckout-summary-message {
    width: 100%;
}

.onestepcheckout-numbers,
.shipping-address-title {
    .h4;
    padding-bottom: @padding-mobile;
}

.onestepcheckout-numbers {
    font-size: 3.2rem;
}

#payment_form_ecard {

    table,
    td {
        border: 0;

        img {
            max-width: 50px;
            display: block;
        }

        .pay_col {
            img {
                max-width: 100%;
            }
        }
    }

    td {
        padding: 0;
    }
}

#billing_address_list>li>div:nth-child(1):nth-last-child(2),
#shipping_address_list ul>li>div:nth-child(1):nth-last-child(2) {
    width: calc(~"50% - @{padding-mobile}");
    flex: unset;
}

#billing_address_list,
#shipping_address_list {
    .clearfix {

        &::before,
        &::after {
            content: none;
        }
    }

    #onestepcheckout-email-error {
        width: 100%;
    }
}

#billing_address_list>li,
#shipping_address_list ul>.clearfix {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    flex-wrap: wrap;

    .input-box {
        flex: 1;

        +.input-box {
            flex: unset;
            width: calc(~"50% - @{padding-mobile}");
        }
    }
}

.maxW(@mobile-max, {
        #payment_form_ecard {
            table {
                img {
                    max-width: 50px;
                }
            }
        }

        table.onestepcheckout-summary {
            td, tr {
                display: flex;
                flex-wrap: wrap;
            }

            .name {
                width: 100%;
            }
        }

        .onestepcheckout-totals {

            // td {
            // display: block;
            // text-align: center;
            // border-width: 1px 0 0;

            // &:first-child {
            // 	padding-top: @padding-mobile;
            // 	background-color: transparent;

            // }
            // &:last-child {
            // 	padding-bottom: @padding-mobile;
            // 	border-top: 0;
            // 	vertical-align: top;
            // }
            // .price {
            // 	text-align: center;
            // }
            // }

            // tr {
            // 	&:first-child {
            // 		td {
            // 			&:first-child {
            // 				border-top: 0;
            // 			}
            // 		}
            // 	}

            // 	&:nth-child(even) {
            // 		background-color: @white;
            // 	}

            // 	&:nth-child(odd) {
            // 		background-color: @main-bg;
            // 	}
            // }
        }

        .onestepcheckout-summary {
            td {
                padding: 3px;
            }

            .name {
                font-size: 1.2rem;
            }
        }

    }

);

.minW(@mobile-max, {
        .checkoutcontainer {

            .editcart {
                width: 40px;
            }
        }
    }

);

.minW(@desktop-min, {

        .checkout-grid {
            display: flex;
            width: 100%;
            justify-content: space-between;
            flex-wrap: wrap;

            .checkout-col {
                width: calc(~"50% - @{padding-mobile}");
            }
        }

        .checkout-grid--reversed {
            flex-direction: row-reverse;
        }

        .onestepcheckout-column-left,
        .onestepcheckout-column-middle {
            width: 100%;
        }

        .onestepcheckout-column-right {
            width: 100%;
        }

        .onestepcheckout-shipping-method {
            margin-bottom: @padding-tablet;
        }

        .onestepcheckout-summary {
            &:not(table) {
                &::after {
                    content: none;
                }
            }
        }

        .checkout-block {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
        }

        .onestepcheckout-coupons {
            width: calc(~"50% - @{padding-mobile}");
            float: right;
        }
    }

);

.minW(@desktop-lg, {
        .onestepcheckout-index-index {
            .group-select {
                padding-top: @padding-desktop;
                margin-top: @padding-tablet;
            }
        }
    }

);