.footer {
  .block-subscribe {
    .block-content {
      background-color: transparent;
    }
  }
}

.page-footer {
  .container;
  text-align: center;
  font-size: 1.4rem;
  background-image: url('../images/newsletter.png');
  background-size: auto 400px;
  background-repeat: no-repeat;
  background-position: top center;
  color: @footer-links;
  padding-bottom: @padding-lg;

  a {
    color: @footer-links;
  }

  .copyright-logo {
    width: auto;
    height: 20px;
    margin: @padding-mobile auto;

    .minW(@desktop-min, {
      margin: 0 @padding-mobile;
    }
    );
  }

  .copyrights {
    width: 100%;

    .minW(@desktop-min, {
      margin-top: @padding-lg;
      float: left;
      display: flex;
    }
    );
  }

  .copyright {
    margin: 0;
  }

  .form.subscribe {
    .block {
      &.newsletter {
        width: 100%;
        max-width: 100%;
        margin: 10px 0 20px;
        padding: 0;
        .field {
          .control {
            position: relative;
            &:before {
              bottom: 10px;
            }
          }
        }
      }
    }
    #newsletter-consent {
      float: left;
    }
  }

}

.footer-menu__item {
  width: 100%;

  .minW(@mobile-max + 1, {
    padding: 0 @padding-mobile @padding-mobile;
    border-left: 1px solid @footer-mobile-border-color;
    margin-bottom: @padding-lg;
    width: 50%;
  }
  );

  .minMax(@mobile-max + 1, @tablet-md - 1, {
    &:nth-of-type(2n + 1) {
      border: 0;
    }
  }
  );

  .minMax(@tablet-md, @desktop-min - 1, {
    width: 33.333%;

    &:nth-of-type(3n + 1) {
      border: 0;
    }
  }
  );

  .minW(@desktop-min, {
    width: 25%;

    &:nth-of-type(4n + 1) {
      border: 0;
    }
  }
  );
}

.footer-menu {
  display: flex;
  flex-wrap: wrap;
  text-align: left;

  .minW(@mobile-max + 1px, {
    margin: @padding-lg auto;
  }
  );

  li {
    padding: @padding-mini 0;
  }

  a {
    display: block;
    transition: color @transition;

    &:hover {
      color: @text-1;
    }
  }
}

.footer-menu__links {
  .list-reset;

  img {
    height: 40px;
    width: auto;
    padding: 5px;
    border: 2px solid @footer-mobile-border-color;
    background-color: @white;
    border-radius: @radius-sm;
    margin-right: 3px;
  }

  &.payments {
    img {
      height: 40px;
      padding: 0;
    }
  }

  .minW(@mobile-max + 1, {
    padding-top: @padding-mobile;
  }
  );
}

.maxW(@mobile-max, {
  .footer-menu {
    margin-left: -@padding-mobile;
    margin-right: -@padding-mobile;
    margin-bottom: @padding-lg;
  }

  .footer-menu__item {
    border-top: 1px solid @footer-mobile-border-color;

    &:last-of-type {
      border-bottom: 1px solid @footer-mobile-border-color;
    }
  }

  .footer-menu__links {
    max-height: 0;
    overflow: hidden;
    transition: max-height 200ms;
    padding: 0 @padding-mobile;

    > li:last-child {
      margin-bottom: @padding-mobile;
    }

    > li:first-child {
      margin-top: @padding-mobile;
    }

    .footer-menu--expanded & {
      max-height: 500px;
    }
  }

  .footer-menu__title {
    padding: 1rem @padding-mobile;
    background-color: @footer-title-mobile-bg;
    color: @text-2;

    &:after {
      content: '\002B';
      .align-v();
      right: @padding-mobile;
      font-size: 160%;
      line-height: 0;
    }

    &:before {
      content: '';
      border-color: @footer-title-mobile-bg transparent transparent transparent;
      border-style: solid;
      border-width: @radius @radius 0 @radius;
      height: 0;
      left: @padding-tablet;
      position: absolute;
      top: 100%;
      width: 0;
      margin-top: -@radius;
      transition: margin-top 200ms ease-in-out;
    }

    .footer-menu--expanded & {
      &:after {
        content: '\2212';
      }

      &:before {
        margin-top: 0;
      }
    }
  }
}
);

.footer-menu__title {
  text-transform: uppercase;
  font-weight: bold;
  position: relative;

  ul {
    list-style: none;
  }

  .minW(@mobile-max + 1px, {
    margin: 0;
  }
  );
}


.minW(@mobile-max, {
  .page-footer {
    float: left;
    clear: both;
    width: 100%;
  }
}
);

.maxW(@mobile-max, {
  #opineo-box {
    position: relative;

    .opineo-side-slider-widget {
      width: auto !important;
      position: fixed;
      top: auto !important;
      bottom: 120px !important;

      &.opineo-side-slider-widget--hidden {
        .opineo-side-slider-widget__iframe {
          display: none;
        }
      }

      &--right {
        transform: none;
      }

      &__label {
        top: 150px;
      }
    }
  }
}
);

.opineo-side-slider-widget__label {

  &:hover,
  &:focus,
  &:active {
    border: 0;
    background: url(https://www.opineo.pl/gfx/frontend/side_slider_gfx.png) no-repeat scroll -631px -7px;
  }

  &::after {
    content: "";
    height: 40px;
    .icon('arrow-small-dark');
    position: absolute;
    top: 120px;
    right: 0;
    left: 0;
    background-color: white;
    background-size: 14px auto;
    background-position: center;
    border: 2px solid @navy;
  }

  .opineo-side-slider-widget--hidden & {
    &::after {
      transform: rotate(180deg);
    }
  }
}