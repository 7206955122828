.double-bounce1,
.double-bounce2 {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: @navy;
    opacity: 0.6;
    position: absolute;
    top: 0;
    left: 0;
    animation: sk-bounce 2.0s infinite ease-in-out;
}

.double-bounce2 {
    animation-delay: -1.0s;
}

.loading-spinner {
    background-color: fade(@white, 85%);
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 101;

    .spinner {
        position: absolute;
        width: 50px;
        height: 50px;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }
}

.add-to-cart-loading-ajax,
.get-taxvat-loading-ajax {
    .loading-spinner();
}

.get-taxvat-loading-ajax {
    .taxvat-message {
        .messages-wrapper();
        .message-inner();
        top: 10px;
        transform: translate(-50%, 0%);
        color: @yellow-dark;
        border-color: @yellow-dark;
    }
}

.product-add-form,
.item-actions {
    .btn-cart:disabled {
        .button-loader(@red);
    }
}

#distributor-form {
    .button-submit-form:disabled {
        .button-loader(@navy);
    }
}

@keyframes sk-bounce {

    0%,
    100% {
        transform: scale(0.0);
    }

    50% {
        transform: scale(1.0);
    }
}