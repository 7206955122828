.block-title {
    position: relative;
    z-index: 2;
    padding-left: @padding-mobile + @padding-mini;
    color: @text-1;
}

.block-content {
    border-bottom-right-radius: @radius;
    border-bottom-left-radius: @radius;
    position: relative;
    margin-bottom: 0;
    background-color: @white;
    padding: @padding-mobile + @padding-mini;

    &:before {
        content: '';
        position: absolute;
        top: -@padding-tablet;
        left: 0;
        right: 0;
        bottom: 100%;
        border-top-right-radius: @radius;
        border-top-left-radius: @radius;
    }

    &.block-content--low-heading {
        border-radius: @radius;
        padding-top: @padding-tablet;

        &:before {
            content: none;
        }

        .block-title {
            .h3;
            padding-left: 0;
            padding-right: 0;
        }
    }
}

.columns .column.main {
    .block-content {
        border-radius: 0;

        &:before {
            content: none;
        }
    }

    .block-title {
        padding-left: 0;
    }
}

.col-left {
    .block {
        margin-bottom: @padding-desktop;
    }
}

.sidebar-additional {

    .block {
        margin-bottom: 0;
    }

    .block-content {
        padding: 0 2rem 2rem;
        box-shadow: 0 8px 30px 4px @rgba-shadow;
    }

    .block-title {
        font-size: 2.4rem;
    }

    .button {
        margin-top: @padding-mobile;
    }
}

.block {
    padding-top: 38px;

    .block-title {
        .h1;
        padding-top: 0;
        padding-bottom: 0;
        background-color: transparent;
    }

    .block-content {
        background-color: @white;
        color: @text-1;

        &:before {
            background-color: @white;
        }

        p {
            margin-bottom: 5px;
            margin-top: 0;
        }

        &.block-content--dark {
            background-color: @block-content-dark;

            &:before {
                background-color: @block-content-dark;
            }
        }
    }
}

.block-layered-nav,
.block-cms-menu {
    padding-top: 0;

    a {
        text-decoration: none;
        display: block;
        padding: 0 @padding-mobile 0 0;

        &:hover,
        &:active,
        &:focus {
            color: @text-2;
        }

        span {
            padding: 1.2rem @padding-mobile;
            display: block;
        }
    }

    .category-img {
        display: none;
    }

    .block-title {
        .h1;
        margin-bottom: @padding-mobile;
        padding-bottom: 0;
        background-color: transparent;
        padding-top: 0;
    }

    .block-content {
        background-color: @gray;
        box-shadow: 0 15px 35px -12px @category-item-shadow;
        padding: 0 0 @padding-mobile;

        &:before {
            background-color: @gray;
        }
    }

    .label-all {
        .screen-readers;
    }

    ul {
        .list-reset;
        text-transform: uppercase;
        font-size: 1.1rem;
        line-height: 1.3;

        li {
            border-top: 1px solid @white;
            margin: 0;

            &:first-of-type {
                border-top: 0;
            }
        }

        ul {
            max-height: 0;
            overflow: hidden;

            a span {
                padding: 1rem @padding-mobile;
            }
        }

        .parent {
            border-top: 1px solid @white;

            >a {
                position: relative;
                padding-right: 30px;

                &:after {
                    content: '';
                    position: absolute;
                    top: 12px;
                    right: @padding-mobile;
                    .icon('arrow-small-dark');
                    height: 12px;
                    width: 12px;
                    transform-origin: 50% 50%;
                    transform: rotate(180deg);
                    transition: transform @transition-short;
                }

                &:hover {
                    &:after {
                        .icon('arrow-small-active');
                        transform: rotate(270deg);
                        top: 10px;
                    }
                }
            }

            >ul {
                a {
                    transition: background-color @transition-short;

                    span {
                        padding-left: @padding-tablet;
                    }

                    &:after {
                        height: 9px;
                        width: 9px;
                    }

                    &:hover {
                        background-color: @main-bg;
                    }
                }

                ul {
                    text-transform: none;

                    a {
                        span {
                            padding-left: @padding-tablet + @padding-mobile;
                        }

                        &:hover {
                            color: @text-1;
                        }
                    }

                    li {
                        &:not(.active) {
                            a {
                                color: @text-2;
                            }
                        }
                    }
                }
            }

            &.menu-active {

                >a {
                    font-weight: 700;
                    color: @text-1;
                    background-color: @white;

                    &:after {
                        .icon('arrow-small-active');
                        transform: rotate(270deg);
                        top: 10px;
                    }
                }

                .menu-active {
                    &:not(:last-child) {
                        border-bottom: 3px solid @gray;
                    }
                }
            }

            li {
                border-top: 1px solid @gray;
                background-color: @white;
            }

            .menu-active {
                +li {
                    border-top: 0;
                }
            }

        }

        .has-active {
            >a {
                &:after {
                    .icon('arrow-small-active');
                }
            }
        }
    }

    .current,
    .active,
    .has-active {
        >a {
            font-weight: 700;
            color: @text-1;
            background-color: @white;
        }
    }

    .menu-active {
        >ul {
            max-height: 900px;
            height: auto;
        }
    }
}

.block-advice {

    .block-content {
        font-size: 1.3rem;
        text-align: center;

        p {
            text-align: left;
        }
    }

    .popup-open {
        .button-dark();
        .button-large();
        display: inline-flex;

        .icon {
            height: 16px;
            width: 26px;
            margin-left: 5px;
        }
    }
}

.block-article {
    padding-top: 0;

    .block-title {
        background-color: @white;
        border-top-right-radius: @radius;
        border-top-left-radius: @radius;
        text-transform: uppercase;
        padding-top: @padding-mobile + @padding-mini;
        padding-bottom: @padding-mobile;
        font-weight: 700;
        line-height: 1.2;
        font-family: @font-1;
        font-size: 1.3rem;
        margin-bottom: 0;

        a {
            text-decoration: none;
        }
    }

    .block-content {
        overflow: hidden;
        color: @text-2;
        font-size: 1.3rem;

        .button {
            .button-dark();
            .button-small();
            float: right;
            margin-top: @padding-tablet;

            &:hover {
                border-color: @yellow;
                background-color: @yellow;
                color: @text-1;
            }
        }
    }

}

.block-videos {
    .std {
        margin-bottom: @padding-tablet;
    }

    .block-content {
        padding-top: 2rem;

        .minW(@mobile-max, {
                padding-top: @padding-tablet;
            }

        );
    }
}

.block-info {

    h2 {
        font-size: 1.4rem;
        font-family: @font-1;
        text-transform: uppercase;

        a {
            text-decoration: none;
        }
    }

    .button {
        .button-red;
        margin-top: @padding-tablet;
    }

    .buttons {
        color: @white;
    }

    .block-content {
        background-color: @navy;
        color: @white;
        border-radius: 0;

        a:not([class^=button]) {
            color: @navy;
        }

        &:before {
            background-color: transparent;
        }
    }

    .maxW(@mobile-max, {
            .article-image {
                text-align: center;
            }
        }

    );

    .minW(@mobile-max, {
            .article-shorts {
                display: flex;
                position: relative;
            }

            .article-image {
                order: 2;
                flex-basis: 40%;
            }

            .article-short {
                padding-right: 2rem;
                flex-basis: 60%;
            }
        }

    );

    .minW(@desktop-min, {
            .buttons {
                position: absolute;
                bottom: 0;
                left: 0;
            }
        }

    );
}

.block-order {
    z-index: 10;

    .super-attribute-select {
        opacity: 1;
        font-family: @font-1;
        border: 0;
        border-bottom: 1px solid @red;
        border-radius: 0;

        &:focus {
            box-shadow: none;
        }
    }

    .maxW(@mobile-max, {
            margin-left: @padding-mobile;
            margin-right: @padding-mobile;
        }

    );

    .minMax(@mobile-max + 1, @tablet-max, {
            margin-left: @padding-tablet;
            margin-right: @padding-tablet;
        }

    );

    .block-title {
        color: @red;
    }

    .button {
        display: block;
        text-align: center;
    }

    .product-options-bottom .box-tocart,
    .box-tocart {
        width: 100%;
        margin: 0;

        .control {
            display: flex;
            height: 36px;

            .input-text {
                .cart-item-qty-edit-input();
            }
        }

        .actions {
            padding-top: 0;
            display: block;
        }

        .action.tocart {
            .button-red;
            display: block;
            margin-left: auto;
            margin-right: auto;
        }

        .field {
            &.qty {
                .cart-item-qty-edit();
                margin: 0;
                padding: 0;
            }

            .input-box;
            margin: 1rem 0;

            .label {
                font-size: 1.2rem;
                font-weight: 500;

                .maxW(@mobile-max, {
                        text-align: left !important;
                    }

                );
            }

            input {
                text-align: center;
            }
        }
    }

    .price-wrapper {
        display: flex;
        justify-content: space-between;
        width: 100%;

        &:before {
            font-family: @font-1;
            color: @text-1;
            font-size: 1.2rem;
            font-weight: 300;
            text-align: left;
            display: flex;
            align-items: flex-end;
            padding-right: @padding-mini;
        }
    }

    .price-box {
        font-weight: 700;
        text-align: right;
        border: 0;

        .regular-price,
        .price-excluding-tax,
        .old-price,
        .special-price {
            display: flex;
            justify-content: space-between;
            align-items: self-end;
            line-height: 1;
            margin-top: 0;
        }

        .price-container {
            flex-direction: column;
        }

        .price {
            font-size: 2.2rem;
            flex: 1 1 auto;
            text-align: right;
        }

        .tax-label {
            font-size: 1.4rem;
            color: @text-1;
            display: block;
            width: 100%;
            text-align: right;
            display: none;
        }

        .price-including-tax {
            margin-bottom: @padding-mobile;

            &:before {
                content: attr(data-label) " "attr(data-tax) "%";
            }
        }

        .price-excluding-tax {
            .price {
                font-size: 1.6rem;
            }
        }

        .old-price {
            .price {
                color: @text-3;
            }

            .price {
                font-size: 1.6rem;
            }

            .price-label {
                min-height: 1.6rem;
            }
        }

        .special-price {
            .price-including-tax {
                color: @red;
            }

            .price-wrapper {
                width: 100%;
            }

            .price-excluding-tax {
                display: flex;
                align-items: baseline;

                .price {
                    font-size: 1.6rem;
                }
            }

            +.old-price .price-container {
                width: 100%;
                display: flex;
                flex-direction: row;
                align-items: baseline;
                justify-content: space-between;

                .price-wrapper {
                    width: auto;
                }
            }
        }
    }

    .program-limitation-qty {
        font-size: 1em;

        .minW(@mobile-max, {
                font-size: 0.9em;
            }

        );
    }

    .toggle-delivery {
        margin-top: @padding-mobile;
        cursor: pointer;
        -webkit-tap-highlight-color: transparent;
        color: @navy;
        font-weight: 700;
        font-size: 1.2rem;

        .icon-arrow-small {
            transform: rotate(180deg);
        }

        &.active {
            .icon-arrow-small {
                transform: rotate(270deg);
                stroke: @red;
            }
        }
    }

    .product-delivery {
        display: flex;
        align-items: center;
        font-size: 1.2rem;

        .tooltip {
            border-color: @red;
            color: @red;
            width: 26px;
            height: 26px;
            line-height: 23px;
        }

        .delivery-label {
            text-decoration: underline;
        }

        .free-shipping {
            font-weight: 700;
            color: @red;
        }
    }

    .product-delivery-installation {
        margin-top: @padding-mobile;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        font-size: 1.2rem;
        line-height: 1.1;
        font-weight: 700;

        .delivery-content {
            display: flex;
            align-items: center;
        }

        .label {
            text-align: right;
        }

        .icon-arrow-small {
            display: block;
            margin: 0 1rem;
            stroke: @red;
        }

        .value {
            color: @red;
        }
    }

    .old-price {
        text-decoration: none;

        .price-container {
            width: 100%;
            display: flex;
            flex-direction: row;
        }

        .price {
            text-decoration: line-through;
        }
    }

    .price-wrapper {
        align-items: center;
    }

    .price-box {
        .old-price .price-label {
            display: flex;
            font-family: @font-1;
            color: @text-1;
            font-size: 1.2rem;
            font-weight: 300;
            text-align: left;
            white-space: nowrap;
        }
    }

    .mobile-price-toggle {
        display: block;
        text-align: center;
        z-index: 10;
        position: relative;
        margin-top: 1rem;

        &:hover {
            .icon {
                stroke: @navy;
                animation: move-up-down 1s linear infinite;
            }
        }

        .icon {
            transition: transform @transition;
            transform: rotate(90deg);
            margin: auto;
            stroke: @text-2;
            position: relative;
        }
    }
}

.block-order-inactive {
    .button {
        .button-red;
    }

    .maxW(@tablet-max, {
            border-radius: @radius * 2;
            text-align: center;
        }

    );
}

.maxW(@ipad-v - 1px, {
        .block-videos {
            .videos {
                margin-left: -@padding-tablet;
                margin-right: -@padding-tablet;
            }

            .youtube {
                border-radius: 0;
            }
        }
    }

);

.maxW(@mobile-max, {
        .block-videos {
            .videos {
                margin-left: -@padding-mobile - @padding-mini;
                margin-right: -@padding-mobile - @padding-mini;
            }
        }
    }

);

.maxW(@tablet-max, {
        .block-order {
            box-shadow: @box-shadow-block;
            border-radius: @radius * 2 @radius * 2 0 0;

            .block-content {
                border-radius: @radius * 2 @radius * 2 0 0;
                transition: max-height @transition;
                display: flex;
                flex-direction: column;

                .product-add-form {
                    order: 2;
                }

                .price-info {
                    .price-box.price-final_price {
                        padding-bottom: 0;
                    }
                }

                .price-final_price {
                    margin-top: 0;

                    .normal-price {
                        margin-bottom: 0;
                        position: relative;

                        .price-label {
                            position: absolute;
                            top: -20px;
                            right: 0;
                        }
                    }
                }

                .product-options-wrapper {
                    margin-top: 2rem;

                    .fieldset>.field.configurable:last-of-type {
                        margin-bottom: @padding-mini;
                    }
                }

                &:not(.expanded) {
                    max-height: 150px;

                    .price-info {
                        display: flex;
                        align-items: flex-end;

                        >div:first-of-type {
                            flex-grow: 1;
                            margin-right: @padding-mobile;
                        }

                        .price-including-tax {
                            margin-bottom: 0;
                        }

                        .price-excluding-tax, .old-price {
                            display: none !important;
                        }
                    }

                    .box-tocart .fieldset {
                        flex-direction: column;
                        width: 100%;

                        .field.qty {
                            display: none;
                        }
                    }

                    .product-delivery-installation,
                    .product-options-wrapper,
                    .product-siblings {
                        display: none;
                    }
                }

                &.expanded {
                    max-height: 90vh;

                    .mobile-price-toggle .icon {
                        transform: rotate(-90deg);
                    }

                    .price-info {
                        .icon-delivery-installation.visible-mobile {
                            display: none;
                        }
                    }

                    .product-delivery-installation {
                        flex-direction: row-reverse;

                        .label {
                            text-align: left;
                        }
                    }

                    .box-tocart {
                        .fieldset {
                            flex-direction: column;
                            width: 100%;

                            .field.qty {

                                width: 100%;

                                .label {
                                    position: absolute;
                                    top: 7px;
                                }

                                .control {
                                    justify-content: center;
                                    width: 100%;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

);

.minW(@mobile-max, {
        .column.main {
            .block-content {
                padding-bottom: @padding-tablet;
            }

            .block-content,
            .block-title {
                padding-left: @padding-tablet;
                padding-right: @padding-tablet;
            }
        }

    }

);

.minW(@ipad-v, {
        .block-videos {
            .block-content {
                display: flex;
                justify-content: space-between;
            }

            .std {
                padding-right: @padding-tablet;
                width: 40%;
                margin-bottom: 0;
            }

            .videos {
                width: calc(~"60% - @{padding-tablet}");
            }

        }
    }

);

.minW(@desktop-min, {
        .sidebar-additional {
            .block {
                padding-bottom: @padding-tablet;
                padding-top: 0;

                &:first-of-type {
                    padding-top: 28px;
                }
            }
        }
    }

);

.minW(@desktop-lg, {
        .block-article {
            .block-content {
                .button {
                    padding: .8rem 2.6rem;
                    font-size: 1.2rem;
                }
            }
        }

        .block {
            .block-title {
                font-size: 3rem;
            }

            &.block-layered-nav,
            &.block-cms-menu {
                .block-title {
                    font-size: 4rem;
                }
            }

            &.block-article {
                .block-title {
                    font-size: 1.3rem;
                }
            }
        }

        .block-layered-nav,
        .block-cms-menu {

            a span {
                padding-left: @padding-mobile + @padding-mini;
                padding-right: @padding-mobile + @padding-mini;
            }

            .block-title {
                padding-left: @padding-mobile + @padding-mini;
                padding-right: @padding-mobile + @padding-mini;
                margin-bottom: .3em;
            }

            ul .parent > a:after {
                right: @padding-mobile;
            }
        }

        .block-videos {
            .std {
                font-size: 1.6rem;
                padding-right: @padding-desktop;
            }
        }

        .block-order {
            .price-box {
                .price {
                    font-size: 2.8rem;
                }

                .tax-label {
                    font-size: 1.6rem;
                }

                .price-label {
                    min-height: 2.8rem;
                }
            }

            .highlight {
                font-size: 1.4rem;
            }

            .product-delivery {
                .value {
                    font-size: 2rem;
                }
            }
        }

        .sidebar-additional {
            .block {
                &:first-of-type {
                    padding-top: 52px;
                }
            }
        }
    }

);

.block.block-products-list {
    .block-title {
        display: flex;
        justify-content: space-between;
    }

    .slider-progress-nav {
        position: static;
    }
}

@keyframes move-up-down {

    0%,
    100% {
        top: 0;
    }

    50% {
        top: 10px;
    }
}