.search-autocomplete {
    position: absolute;
    top: 100%;
    left: auto;
    right: 0;
    text-align: center;
    background-color: @white;
    z-index: 101;
    box-shadow: @box-shadow-message;
    border-radius: @radius;
    overflow: hidden;
    margin-top: @padding-mini;
    color: @text-1;
    line-height: 1.2;
    font-size: 1.3rem;

    .autocomplete-list {
        color: @text-1;
        line-height: 1.2;
        font-size: 1.3rem;
        padding: 0;
        max-height: 320px;
        overflow: auto;
        position: relative;

        dd[role="option"] {
            .transition(background-color .2s);
            border-width: 0 0 1px 0;
            border-bottom: 1px solid @border-light;
            margin: 0;
            padding: @padding-mini * 2;
            display: flex;
            justify-content: space-between;
            line-height: 1;

            &:hover,
            &.selected {
                background-color: @yellow;
            }

            &:last-child {
                border-bottom: 0;
            }
        }

        .title-category {
            ~dd[role="option"] {
                display: block;
            }
        }

        .title-term,
        .title-term~dd[role="option"] {
            display: none;
        }

        .autocomplete-list-title {
            position: sticky;
            top: 0;
            right: 0;
            left: 0;
            z-index: 100;
            background-color: @gray;
            border: 0;
            text-align: center;
            color: @text-1;
            padding: 7px @padding-mini;
            border: 0;
        }
    }

    .maxW(@desktop-min, {
            .align-h();
        }

    );

    .cms-home & {
        width: 100%;
    }

    .name {
        flex: 3;
        text-decoration: none;
    }

    a {
        color: @text-2;

        &:hover {
            color: @text-2;
        }

        strong {
            color: @text-1;
            font-weight: 900;
        }
    }

    .button-all-results {
        float: right;
        color: @text-1;
        font-weight: 900;

        &:active,
        &:focus,
        &:hover {
            border: 0;
        }
    }

    .autocomplete-info {
        display: flex;
        width: 100%;
    }

    .autocomplete-sku {
        flex: 1;
        padding-left: @padding-mini;
    }

    dl dd[role="option"] {
        .amount {
            position: static;
        }
    }
}

.smile-elasticsuite-autocomplete-result {
    .clearfix;
}

.searchautocomplete-loader {
    display: none;
    position: absolute;
    width: 18px;
    height: 18px;
    z-index: 99;
    top: 50%;
    right: 5rem;
    transform: translateY(-50%) scale(0.6);

    div {
        position: absolute;
        background-color: @white;
        height: 3px;
        width: 3px;
        border-radius: 4px;
        animation-name: f_autocomplete_loader;
        animation-duration: 0.64s;
        animation-iteration-count: infinite;
        animation-direction: normal;
    }

    .processing & {
        display: block;
    }

    .minW(@desktop-min, {
            right: 1rem;
        }

    );

    >div {
        &:nth-child(1) {
            left: 0px;
            top: 7px;
            animation-delay: 0.24s;
        }

        &:nth-child(2) {
            left: 2px;
            top: 2px;
            animation-delay: 0.32s;
        }

        &:nth-child(3) {
            left: 7px;
            top: 0px;
            animation-delay: 0.4s;
        }

        &:nth-child(4) {
            right: 2px;
            top: 2px;
            animation-delay: 0.48s;
        }

        &:nth-child(5) {
            right: 0px;
            top: 7px;
            animation-delay: 0.56s;
        }

        &:nth-child(6) {
            right: 2px;
            bottom: 2px;
            animation-delay: 0.64s;
        }

        &:nth-child(7) {
            left: 7px;
            bottom: 0px;
            animation-delay: 0.72s;
        }

        &:nth-child(8) {
            left: 2px;
            bottom: 2px;
            animation-delay: 0.8s;
        }
    }

}

.searchautocomplete-placeholder {
    box-shadow: @box-shadow-message;
    border: 0;
    border-radius: @radius;
    overflow: hidden;
}

.advanced-search-open {
    display: block;
    padding: 7px @padding-mini;
    color: @text-1;
    cursor: pointer;
    transition: background-color @transition-short;

    &:hover {
        background-color: @yellow;
    }

    .cms-home & {
        display: none;
    }
}

.minW(@mobile-max, {
        .search-autocomplete {
            min-width: 400px;
            width: 115%;
        }
    }

);

.minW(@desktop-lg, {

        .page-header {
            .search-autocomplete {
                top: 5rem;
            }
        }
    }

);

.maxW(@desktop-min, {
        .search-autocomplete {
            .page-header & {
                width: 94%;
                margin-left: auto;
                margin-right: auto;
                top: 28px;
            }
        }
    }

);

@keyframes f_autocomplete_loader {
    0% {
        background-color: #000;
    }

    100% {
        background-color: @white;
    }
}
