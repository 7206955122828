@import '../modules/index/slider';
@import '../modules/index/banners';
@import '../modules/index/search';
@import '../modules/index/benefits';
@import '../modules/index/slider-main';

.cms-home {

    .page-main {
        background-color: @main-bg;
    }

    .header-minicart {
        border-left: 0;
    }

    .searchautocomplete-placeholder {
        .advanced-search-open {
            display: none;
        }
    }

    .page-main {
        max-width: 100%;
        padding: 0;
    }

    .columns .column.main {
        width: 100%;
        padding: 0;
    }
}

.minW(@desktop-min, {
        .cms-home {
            .header-search {
                display: none;
            }
        }
    }

);