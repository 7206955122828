@products-button-height: @menu-height - 10px;
@mobile-menu-offset: 110px;
@mobile-menu-padding: 45px;

.section-category-button {
    background-color: @navy;
    padding-top: @padding-mobile;
    padding-bottom: @padding-mobile;
    display: flex;
    justify-content: center;

    .nav-products-button {
        .button-nav-products;
        .nav-button-link();
        height: @products-button-height;
        min-width: 280px;

        .icon-products {
            margin-right: 1rem;
        }

        .icon-arrow-down {
            margin-left: auto;
        }
    }
}

.nav-primary {
    font-family: @font-2;
    top: @menu-height + @search-height - 2px;
    font-size: 1.4rem;
    margin: 0;
    overflow-x: hidden;
    overflow-y: auto;

    .nav-products {
        order: 0;
        border-radius: 40px;

        .nav-products-button {
            .button-nav-products;
            .nav-button-link();
            height: @products-button-height;
            min-width: 250px;
        }

        .icon-arrow-down {
            margin-left: auto;
        }
    }

    a {
        display: block;
        padding: .8rem @padding-mobile;
        .transition(color .2s);

        .icon {
            .transition(stroke .2s);

            .minW(@desktop-min, {
                    display: block;
                }

            );
        }

        &:hover {
            .icon {
                stroke: @red;
            }
        }
    }

    li {
        .transition(opacity .2s);
        margin-bottom: 0;
    }

    ul {
        top: 39px;
        background-color: @white;

        a {
            color: @text-2;
        }
    }

    .label-all {
        display: none;
    }

    .menu-open & {

        .menu-active,
        .current {
            >a {
                color: @red;
            }
        }

        .icon {
            stroke: @red;
        }
    }

    .icon-products {
        margin-right: 1rem;
    }
}

.nav-8-1>a {
    // promocja urodzinowa
    color: @red  !important;
    font-weight: 700;
}

.category-img {
    height: 110px;
    width: 110px;
    margin: @padding-mini @padding-mobile @padding-mobile;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
        max-width: 100%;
        max-height: 100%;
    }
}

.maxW(@tablet-max, {
        .nav-primary {
            .translate-hide();
            background-color: @white;
            overflow: hidden;

            .menu-open & {
                .translate-show();
                height: calc(~"100vh - @{menu-height} - @{search-height} - 20px");
                width: 100vw;
                overflow: hidden;
            }

            .active {
                > a {
                    color: @red;
                }
            }

            .level0 {
                overflow: hidden;
            }

            .nav-products {
                border-radius: 0;

                &:not(.menu-active) {
                    background: @navy;
                    padding: 5px 0 1rem;

                    &:after {
                        content: none;
                    }
                }
            }

            .nav-products-button {
                width: 280px;

                .icon {
                    display: block;
                }
            }

            .menu-active {
                overflow: hidden;

                .nav-products-button {
                    color: @white;
                }

                > .level0 {
                    overflow: hidden;
                }
            }

            .current {
                overflow: hidden !important;

                > .level0 {
                    position: fixed;
                    overflow-y: auto !important;
                    top: 130px !important;

                    > li {
                        &:first-child {
                            border-top: none;
                        }
                    }
                }
            }

            .icon {
                display: none;
            }

            li {
                background-color: @white;
                transition-delay: .2s;
                text-align: center;
                font-family: @font-1;
                font-weight: 700;

                a:not([class^=button]) {
                    color: @navy;
                }

                > span {
                    display: block;
                }
            }

            ul {
                .translate-hide();
                height: auto;
                width: 100vw;
                overflow-x: hidden;
            }

            .parent {
                position: relative;
                padding-right: @mobile-menu-padding;
                padding-left: @mobile-menu-padding;

                &:after {
                    content: '';
                    .align-v();
                    right: @padding-mobile;
                    .icon('arrow-small-dark');
                    height: 14px;
                    width: 14px;
                    transform-origin: 50% 25%;
                    transform: rotate(180deg);
                    pointer-events: none;
                }

                &.menu-active {
                    &:after {
                        .icon('arrow-small-active');
                    }
                }

                > a {
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;
                }

                &.menu-active {
                    position: fixed;
                    top: 91px;
                    right: 0;
                    left: 0;
                    padding-left: @mobile-menu-padding;
                    z-index: 100;

                    &:after {
                        left: @padding-mobile;
                        transform: rotate(0deg);
                        top: 13px;
                    }

                    > ul {
                        top: 39px;
                        .translate-show();
                        bottom: 0;
                    }
                }
            }
        }

        .category-img,
        .level-top.last {
            display: none;
        }

        .skip-account {
            .menu-open & {
                .button-border(@white, @navy);

                .label {
                    color: @white;
                }
            }

        }
    }

);

.maxW(@ipad-v - 1, {
        .nav-primary {
            top: @mobile-menu-offset;

            .menu-open & {
                height: calc(~"100vh - @{menu-height} - @{mobile-call-height} - @{search-height} - 20px");
            }

            .cms-home.menu-open & {
                height: calc(~"100vh - @{menu-height} - @{mobile-call-height}");
            }

            .parent.menu-active {
                top: @mobile-menu-offset + @mobile-call-height;
            }

            .current {
                > .level0 {
                    top: @mobile-menu-offset + @mobile-call-height + @products-button-height  !important;
                }
            }

            .cms-home & {
                top: @menu-height;

                .parent.menu-active {
                    top: @menu-height + @mobile-call-height;
                }

                .current {
                    >.level0 {
                        top: @menu-height + @mobile-call-height + @products-button-height  !important;
                    }
                }
            }
        }
    }

);

.minMax(@ipad-v, @tablet-max, {
        .nav-primary {
            top: @mobile-menu-offset;

            .parent.menu-active {
                top: @mobile-menu-offset  !important;
            }

            .current {
                > .level0 {
                    top: @mobile-menu-offset + @products-button-height  !important;
                }
            }

            .cms-home & {
                top: @menu-height;

                .parent.menu-active {
                    top: @menu-height  !important;
                }

                .current {
                    > .level0 {
                        top: @menu-height + @products-button-height  !important;
                    }
                }
            }
        }

    }

);

.minW(@mobile-max, {
        .nav-primary {
            a {
                padding-left: @padding-tablet;
                padding-right: @padding-tablet;
            }

            .menu-active, .current {
                > a {
                    .label-all {
                        display: inline;
                    }
                }
            }

            .parent {
                &:after {
                    right: @padding-tablet;
                }

                &.menu-active {
                    &:after {
                        left: @padding-tablet;
                    }
                }
            }
        }
    }

);

.minW(@desktop-min, {
        .nav-primary {
            position: absolute;
            top: 0;
            right: auto;
            left: calc(~"@{logo-w} + @{padding-tablet} + @{padding-mobile}");
            display: flex;
            align-items: center;
            min-height: @menu-height;

            a {
                padding-left: @padding-mobile;
                padding-right: @padding-mobile;
            }

            .active {
                .icon {
                    &:not(.icon-arrow-small) {
                        stroke: @red;
                    }
                }
            }

            .nav-contact {
                order: 1;
                margin-left: 2rem;
            }

            .nav-products {

                > ul {
                    transform: translateY(20px);
                    .transition(transform @transition-short, opacity @transition-short; );
                    font-family: @font-1;
                    opacity: 0;
                    position: fixed;
                    top: @menu-height;
                    left: @padding-desktop;
                    right: @padding-desktop;
                    height: 85vh;
                    max-height: 0;
                    overflow: hidden;
                    display: flex;
                    justify-content: stretch;
                    border-radius: 10px;
                    max-width: 1200px;
                    margin-left: auto;
                    margin-right: auto;

                    .menu-open & {
                        transform: translateY(0);
                        opacity: 1;
                        max-height: 800px;
                    }

                    > li {
                        border-bottom: 1px solid @border-light;
                        height: @menu-height;
                        flex: 1;
                        line-height: 1.1;

                        > a {
                            text-align: center;
                            text-transform: uppercase;
                            color: @text-2;
                            font-size: 1.2rem;
                            cursor: pointer;
                            position: relative;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            height: 100%;
                            padding: 0 @padding-mini*2;

                            &:after {
                                content: '';
                                position: absolute;
                                bottom: -1px;
                                left: 0;
                                right: 0;
                                border-bottom: 2px solid @red;
                                transform: scaleX(0);
                                .transition(transform .3s);
                            }
                        }

                        > ul {
                            position: absolute;
                            top: @menu-height;
                            right: 0;
                            left: 0;
                            z-index: -1;
                            opacity: 0;
                            height: 0;
                            .transition(opacity .2s);
                            transition-delay: .2s;
                            display: flex;
                            flex-wrap: wrap;
                            overflow: hidden;

                            > li {
                                width: 25%;
                                padding: @padding-mobile;
                                border-right: 1px solid @border-light;

                                > a {
                                    text-transform: uppercase;
                                    color: @text-1;
                                    font-weight: 900;
                                }

                                &.active {
                                    > a {
                                        text-decoration: underline;
                                    }
                                }

                                a {
                                    padding: .5rem 0;

                                    &:hover {
                                        text-decoration: underline;
                                    }
                                }

                                > ul {
                                    padding-left: @padding-mobile;

                                    a {
                                        padding: .5rem 0;

                                        &:hover {
                                            color: @red;
                                        }
                                    }

                                    .active {
                                        > a {
                                            color: @red;
                                        }
                                    }
                                }
                            }
                        }

                        &.menu-active {
                            background-color: @category-active-bg;

                            > a {
                                color: @red;

                                &:after {
                                    transform: scaleX(1);
                                }
                            }

                            > ul {
                                z-index: 99;
                                opacity: 1;
                                height: auto;
                                z-index: 10;
                                top: @menu-height;
                                max-height: 800px - @menu-height;
                                max-height: calc(~"100% - @{menu-height}");
                                overflow-y: auto;
                            }
                        }

                        &.active {
                            background-color: @category-active-bg;

                            > a {
                                color: @red;
                            }
                        }

                        &:hover {
                            background-color: @category-active-bg;

                            > a {
                                color: @red;

                                &:after {
                                    transform: scaleX(1);
                                }
                            }
                        }
                    }
                }

                .menu-open & {
                    background-color: @white;

                    > a {
                        color: @text-1;
                    }

                    .icon-arrow-down {
                        transform: rotate(180deg);
                    }
                }
            }

            .nav-contact-button {
                .button-navy-outline();
                .nav-button-link();

                .label {
                    margin-left: 1rem;
                }
            }

            > li {
                position: static;

                > a {
                    display: flex;
                    align-items: center;
                    height: @menu-height;
                }
            }

            .menu-open & {
                > li {
                    &:not(.nav-products) {
                        .link-hidden;
                    }
                }
            }

            .account-open & {
                > li {
                    .link-hidden;
                }
            }

            .menu-active, .current {
                > a {
                    .label-all {
                        display: none;
                    }
                }
            }
        }

        .nav-contact {
            ul {
                display: none;
            }
        }

        .icon-contact {
            max-width: 20px;
            max-height: 20px;
            margin-right: 1rem;
            width: 20px;
            height: 20px;
        }

        .account-open {

            .skip-account {
                background-color: @navy;

                .label {
                    color: @white;
                }

                .icon {
                    stroke: @white;
                }
            }
        }
    }

);

.minW(@desktop-md, {
        .nav-primary {
            left: calc(~"@{logo-w-md} + 100px");
        }
    }

);

// menu height

.minW(@desktop-lg, {

        .nav-primary {
            left: calc(~"@{logo-w-lg} + 100px");
            min-height: @menu-height-lg;

            > li {
                > a {
                    height: @menu-height-lg;
                }
            }

            .nav-products {
                .nav-products-button {
                    min-width: 320px;
                    height: @menu-height-lg - 28px;
                }

                > ul {
                    top: @menu-height-lg;
                    max-width: 1400px;

                    > li {
                        height: @menu-height-lg;

                        &.menu-active {
                            > ul {
                                top: @menu-height-lg;
                                max-height: calc(~"100% - @{menu-height-lg}");
                            }
                        }

                        > a {
                            font-size: 1.4rem;
                        }

                        > ul {
                            top: @menu-height-lg;
                        }
                    }
                }
            }
        }

        .skip-links,
        .page-header .container {
            min-height: @menu-height-lg;
        }

        .icon-phone {
            height: 30px;
            width: 30px;
        }

        .menu-open, .minicart-open, .account-open {
            .main-container {
                &:before {
                    height: @menu-height-lg;
                }
            }
        }

        .header-account {
            top: @menu-height-lg;
        }

    }

);
