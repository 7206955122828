.cms-program_lojalnosciowy {
    .std {

        h1 {
            text-align: right;
        }

        .top-klub-cms-content {
            display: flex;
            flex-direction: row;
        }

        .top-klub-cms-logo {
            flex-grow: 1;
            flex-shrink: 0;
            flex-basis: 50%;
            text-align: center;
        }

        .top-klub-cms-text {
            strong {
                color: @red;
            }
        }

        .maxW(@ipad-v, {
                h1 {
                    text-align: center;
                }

                .top-klub-cms-content {
                    flex-direction: column;
                }

                .top-klub-cms-logo {
                    margin: @padding-mobile auto;
                }
            }

        );
    }
}