.benefits-container {
    text-align: center;
    background-color: @white;

    ul {
        .list-reset;
        .container;
        display: flex;
        flex-direction: column;
    }

    li {
        padding: 4rem 0;
        width: 340px;
        max-width: 100%;
    }

    p {
        color: @text-2;
        max-width: 100%;
    }

    .icon {
        margin: @padding-mobile 0;
        stroke: @text-1;
    }

    img {
        margin: @padding-mobile 0;
        height: 70px;
        width: auto;
        text-align: center;
    }
}

.benefit-form {
    margin-top: @padding-mobile;

    .loading {
        pointer-events: none;
        opacity: 0.5;
    }

    .button-submit-form:disabled {
        .button-loader(@navy);
    }
}

.maxW(@mobile-max, {
        .benefits-container {
            li {
                margin: auto;
            }
        }
    }

);


.minW(@mobile-max, {
        .benefits-container {
            ul {
                flex-direction: row;
                flex-wrap: wrap;
                justify-content: space-around;

                li {
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    align-items: center;
                    padding-right: @padding-mini;
                    padding-left: @padding-mini;
                    max-width: 300px;
                }
            }
        }
    }

);

.minW(@desktop-lg, {
        .benefits-container {
            ul {
                li {
                    max-width: 340px;
                }
            }
        }
    }

);