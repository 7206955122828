#CybotCookiebotDialog {
    background-color: @navy  !important;

    #CybotCookiebotDialogBody,
    #CybotCookiebotDialogDetailBody {
        max-width: 1200px;
    }

    #CybotCookiebotDialogBody,
    #CybotCookiebotDialogDetail {

        div,
        a {
            font-family: @font-1;
        }
    }

    #CybotCookiebotDialogBody,
    #CybotCookiebotDialogDetailBody,
    #CybotCookiebotDialogDetail,
    #CybotCookiebotDialogDetailBodyContent,
    .CybotCookiebotDialogDetailBodyContentCookieContainerTypes,
    .CybotCookiebotDialogDetailBodyContentIABv2Tab,
    .CybotCookiebotDialogDetailBodyContentCookieContainerTypesSelected,
    .CybotCookiebotDialogDetailBodyContentIABv2TabSelected,
    .CybotCookiebotDialogDetailBodyContentTabsItemSelected,
    .CybotCookiebotDialogDetailBodyContentTabsItem,
    #CybotCookiebotDialogDetailFooter {
        background-color: @navy;
    }

    #CybotCookiebotDialogDetailBodyContentTabsAbout {
        border-color: transparent;
    }

    .CybotCookiebotDialogDetailBodyContentCookieTypeTable thead td,
    .CybotCookiebotDialogDetailBodyContentCookieTypeTable thead th {
        background-color: @navy  !important;
    }

    .CybotCookiebotDialogDetailBodyContentCookieContainerTypes {
        color: rgba(255, 255, 255, .5) !important;
    }

    .CybotCookiebotDialogDetailBodyContentCookieContainerTypes,
    .CybotCookiebotDialogDetailBodyContentCookieContainerTypesSelected {
        border-right-color: transparent;

        &:hover {
            border-right-color: transparent;
        }
    }

    #CybotCookiebotDialogDetailBodyContent {
        border-color: @navy;
    }

    #CybotCookiebotDialogBodyButtonAccept {
        .button-red;

        &:hover,
        &:active,
        &:focus {
            color: @white;
            border-color: @red;
            background-color: transparent;
        }
    }

    #CybotCookiebotDialogBodyButtonDecline {
        .button;
        color: @white;
        border-color: @white;

        &:hover,
        &:active,
        &:focus {
            color: @white;
            border-color: @red;
        }
    }

    #CybotCookiebotDialogDetailFooter {
        text-align: left;
    }
}

#CybotCookiebotDialogDetailBodyContentTabsAbout:after {
    content: '';
    height: 1px;
    width: 100%;
    background: #404040;
    position: absolute;
    right: 0;
    top: 100%;
}

.CybotCookiebotDialogBodyButton {
    width: auto !important;
}

#CybotCookiebotDialog,
#CybotCookiebotDialogBodyUnderlay {
    max-width: 100% !important;
}