.customer-account-login {
    .login-container {
        .block {
            .block-title {
                border: 0;

                strong {
                    .h3;
                }
            }
        }
    }
}
