.firecheckout {

    &.page-layout-1column .columns {
        max-width: @desktop-sm;
        margin: auto;
    }

    #maincontent {
        #checkout {
            .authentication-wrapper .action-auth-toggle {
                min-width: 140px;
                .button-red;

                &:hover,
                &:focus,
                &:active {
                    .button-red;
                }
            }
        }

        .block-customer-login {
            padding-top: 0;

            .block-content {
                padding: 0;
            }

            .block-title {
                padding-left: 0;

                strong {
                    font-weight: 700;
                }
            }

            .messages {
                top: 70%;

                .message-error {
                    padding: 1rem 1rem 1rem 4rem;
                }
            }

            .g-recaptcha {
                margin: 10px 0 10px 7px;
            }

            button,
            button:hover,
            button:focus,
            button:active {
                .button;

                &.action-login {
                    .button-dark;
                }
            }

            .fieldset {
                margin-bottom: 0;
            }

            .actions-toolbar {
                padding: 0;
                margin: 0;
                flex-wrap: wrap;

                >.primary {
                    padding-top: 1rem;

                    .action {
                        margin-left: 0;
                    }
                }

                >.secondary {
                    margin-right: 0;
                    padding-top: 1rem;
                }
            }
        }
    }

    .actions-toolbar .primary button.action.primary,
    .actions-toolbar .primary button.action.continue,
    .actions-toolbar .primary button.action.continue[data-role="opc-continue"] {
        background-color: @red;
        border-color: @red;
        font-size: 1.6rem;
        border-radius: 40px !important;
        padding: 10px 25px;
        color: @white;

        &:hover,
        &:focus,
        &:active {
            background-color: @white;
            border-color: @red;
            color: @text-1;
            border-radius: 40px;
            padding: 10px 25px;
        }

        &.checkout {
            white-space: normal;
        }
    }

    .actions-toolbar .primary button.action-apply {
        border-radius: 40px !important;
        padding: 10px 25px;

        &:hover,
        &:focus,
        &:active {
            padding: 10px 25px;
        }
    }

    .checkout-agreements-block {
        padding-bottom: 0;
        padding-top: @padding-mobile;
        margin-top: 2.5rem;
        border-top: 1px solid @border-light;

        .checkout-agreements {
            margin-bottom: 0;
            padding-bottom: 0;
        }

        .label .action-show {
            color: @text-1;
        }
    }

    .place-order .actions-toolbar .primary .action.checkout {
        width: auto;
        margin: 0 auto @padding-tablet auto !important;
        padding: @padding-mobile @padding-tablet  !important;

        .maxW(@mobile-max, {
                max-width: 70%;
                padding-left: 1rem !important;
                padding-right: 1rem !important;
            }

        );
    }

    .checkout-billing-address {
        margin-top: 20px;

        button {

            &.action,
            &.action:hover,
            &.action:focus,
            &.action:active {
                .button;
                .button-small;
            }
        }

        .billing-address-details {
            padding-left: 0;
            padding-bottom: @padding-mobile;
        }
    }

    .checkout-shipping-address .new-address-popup .action-show-popup {

        &.action,
        &.action:hover,
        &.action:focus,
        &.action:active {
            .button-dark;
        }
    }

    .checkout-shipping-address button.action.action-select-shipping-item {
        color: @red;
        border-color: @red;

        &:hover {
            background-color: @red;
            border-color: @red;
            color: #fff;
        }
    }

    .modal-popup .modal-footer {
        padding: 1rem;

        .primary {

            &.action,
            &.action:hover,
            &.action:focus,
            &.action:active {
                .button-dark;
            }
        }

        .secondary {

            &.action,
            &.action:hover,
            &.action:focus,
            &.action:active {
                .button;
            }
        }
    }

    .actions-toolbar .primary .action {

        &.action-update,
        &.action-update:hover,
        &.action-update:focus,
        &.action-update:active {
            .button-dark;
        }

        &.action-cancel,
        &.action-cancel:hover,
        &.action-cancel:focus,
        &.action-cancel:active {
            .button;
        }
    }

    .step-title,
    .title {
        line-height: 1.2;
        font-weight: 700;
        color: @navy;
    }

    .loading-mask .loader:after {
        border-top-color: @red  !important;
    }

    .form-shipping-address,
    #checkout .control {
        input {
            &[type=checkbox] {
                border-radius: 0;
            }

            &[type=checkbox],
            &[type=radio] {
                position: relative;
                border: 2px solid @navy;

                &:focus {
                    border-color: @navy;
                }

                &:checked,
                &:checked:focus {
                    box-shadow: 0 0 0 10px @navy inset;
                }

                +label {
                    padding-left: 0;

                    span {
                        font-weight: 700;
                        color: @navy;
                    }
                }
            }
        }


        .opc-wrapper {
            .table-checkout-shipping-method {
                width: auto;
                border: 0;
            }
        }

    }

    .checkout-shipping-method {
        .table-checkout-shipping-method {
            tbody td {
                padding: 2rem;
            }

            .col-price .price {
                font-weight: 700;
                color: @navy;
            }

            .col-carrier {
                font-weight: 700;
                .instructions {
                    font-weight: normal;
                    font-size: 0.9em;
                }
            }

        }
    }

    select {
        opacity: 1;
    }

    #checkout {

        .opc-wrapper .step-title:before,
        .opc-block-summary>.title:before,
        .opc-sidebar .step-title:before {
            color: @red;
            border-color: @red;
        }

        .opc-block-summary>.title,
        .opc-wrapper .step-title {
            font-weight: 700;
        }

        .product.options[data-collapsible=true] {
            >.toggle[data-role=title] {
                span {
                    color: @navy;
                    text-transform: none;
                    font-size: 1.4rem;

                    &:after {
                        border-color: @navy;
                        margin-left: 1rem;
                        width: 10px;
                        height: 10px;
                    }
                }
            }

            .item-options {
                margin-bottom: 0;
                color: @text-2;
            }
        }

        .items-in-cart[data-collapsible=true],
        .payment-option[data-collapsible=true] {
            padding-bottom: 3.5rem;
            border-bottom: 1px solid @border-light;

            >.title[data-role=title],
            >.payment-option-title[data-role=title] {
                span {
                    color: @navy;
                    text-transform: none;
                    font-size: 1.6rem;
                }

                strong[role=heading]:after,
                .action-toggle:after {
                    border-color: @navy;
                    margin-left: 2rem;
                    width: 14px;
                    height: 14px;
                }
            }
        }

        .minicart-items {
            .product-item {
                .options.product {
                    padding-bottom: 0;
                }
            }
        }

        .opc-sidebar {
            input[type=checkbox] {
                border-radius: 0;
            }
        }

        .opc-wrapper .step-content .shipping-address-item {
            padding-top: @padding-tablet  !important;

            &.selected-item {
                border-color: @navy;

                &:after {
                    background-color: @navy;
                }
            }

            &.not-selected-item {
                border-color: @border-light;
                color: @text-3;

                a {
                    color: @text-3;
                }

                .action-select-shipping-item {
                    position: absolute;
                    margin: 0;
                    top: -2px;
                    right: -2px;
                    border-radius: 0;
                }
            }
        }
    }

    .items-in-cart {
        .product-image-container {
            margin-right: @padding-mobile;
            border: 1px solid @border-light;
            width: 50px !important;
            height: 50px !important;
        }

        .details-qty {
            height: auto;
        }
    }

    .product-item-name {
        color: @text-1;
    }

    .cart-price .price {
        color: @navy;
        font-weight: 700;
    }

    .opc-block-summary {
        .data.table.table-totals {
            border-top: 2rem solid @white;

            th {
                background: transparent;
                color: @text-1;
                font-weight: 700;
            }

            tr {
                border-bottom: 0;
            }

            .amount {
                color: @navy;
                font-weight: 700;
            }

            .mark strong {
                font-weight: 700;
            }

            .totals-tax {

                .mark {
                    font-weight: 700;
                }

                .amount {
                    font-weight: 500;
                }
            }

            .grand {
                .amount {
                    font-size: 2rem;
                    color: @red;
                }
            }
        }

        .totals.shipping.incl .value {
            display: inline;
            color: @text-1;
        }

        .product-item-name-block {
            vertical-align: bottom;
        }
    }

    .swissup-checkout-fields__field {
        .label {
            line-height: 1.2;
            font-size: 1.4rem;
        }
    }

    [name="captcha_form_id"]+br,
    .grand.totals.excl {
        display: none;
    }

    .modal-inner-wrap {
        .form-shipping-address {
            .fieldset {
                .field[name="shippingAddress.vat_id"] {
                    display: none;
                }
            }
        }
    }

    .modal-content {
        .checkout-agreement {
            display: flex;
            align-items: center;
            gap: 10px;

            .action {
                border: none;
                border-radius: 0;
                padding: 0;
                font-size: 0.8em;
                font-weight: 400;
            }
        }
    }

    #co-payment-form {
        .checkout-billing-address {
            display: none;
        }
    }

    .opc-sidebar {
        #opc-sidebar {
            .shipping-information {
                padding: 0 5px;
                display: none;

                &.show {
                    display: block;
                }

                .shipping-information-title {
                    color: @text-1;

                    .action {
                        border: 0;
                        color: @red;
                        font-weight: normal;
                        font-size: inherit;
                        text-decoration: underline;
                    }
                }
            }
        }
    }


    .payment-method-icon-przelewy24 {
        background: none;
    }

    .przelewy24-payment-method-title, .payment-method-przelewy24__methods {
        margin-bottom: 0;
    }

    .payment-method-icon-przelewy24,
    .payment-method-icon-przelewy24_card,
    .payment-method-icon-przelewy24_blik {
        padding: initial;
        margin-left: 0;
    }

    .payment-method-icon-przelewy24,
    .payment-method-icon-przelewy24_blik {
        height: auto;
        width: 60px;
        padding: 0 10px;
        border: none;
        .minW(@mobile-max, {
            width: 80px;
        });
    }

    .przelewy24-payment-method-description {
        max-width: 60%;
        margin-left: 10px;
        .minW(@mobile-max, {
            max-width: 100%;
        });
    }

    .block-minicart .minicart-items-wrapper {
        max-height: none;
    }

    #checkout {
        .payment-method {
            .payment-method-title {
                .minW(@mobile-max, {
                        display: flex;
                        align-items: center;

                        & > input[type="radio"] + label {
                            display: flex;
                            align-items: center;
                        }
                    }

                );
                &.field {
                    label {
                        padding-left: 5px;
                    }
                }
            }
        }

        .opc-wrapper .opc,
        .opc-sidebar {
            border-radius: 0 !important;
            border: 0 !important;
        }

        .opc-wrapper .opc .step-title,
        .opc-sidebar .opc .step-title,
        .modal-content .opc-block-summary>.title {
            font-weight: 700;
        }
    }

}

.maxW(@mobile-max, {
        #maincontent {
            .block-customer-login {
                .actions-toolbar {
                    justify-content: center;
                }
            }
        }
    }

);

.maxW(@tablet-max, {
        .firecheckout.firecheckout-col3-set {
            .columns {
                max-width: @mobile-max;
            }

            #checkout {

                .opc-wrapper,
                .opc-wrapper .opc > li,
                .opc-sidebar {
                    width: 100%;
                    margin: @padding-mobile auto;
                }
            }
        }
    }

);