.align() {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.align-v() {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}

.align-h() {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
}

.visible-desktop {
    @media (max-width: @tablet-max) {
        display: none !important;
    }
}

.visible-mobile {
    @media (min-width: @desktop-min) {
        display: none !important;
    }
}

.hidden {
    display: none !important;
}

.success {
    color: @success;
}

.error {
    color: @error;
}

.nowrap {
    white-space: nowrap;
}

.list-reset {
    list-style: none;
    padding: 0;
}

.content-link() {
    text-decoration: underline;
    color: @text-1;
    transition: color @transition-short;

    &:hover,
    &:active,
    &:focus {
        color: @red;
    }
}

.highlight() {
    display: inline-block;
    padding: @padding-mini @padding-mobile;
    background-color: @yellow;
    font-weight: 400;
    font-size: 1.2rem;
    margin-top: @padding-mobile;
    text-align: left;

    strong {
        padding-left: 3rem;
    }
}

.icon(@icon) {
    background-image: url(~"../images/icons/@{icon}.svg");
    background-repeat: no-repeat;
}

.uppercase {
    text-transform: uppercase;
}

.screen-readers {
    position: absolute;
    left: -10000px;
    top: auto;
    width: 1px;
    height: 1px;
    overflow: hidden;

    &:focus {
        position: static;
        width: auto;
        height: auto;
    }
}

.translate-hide() {
    position: absolute;
    left: 0;
    right: 0;
    transform: translateX(-120vw);
    // .transition(transform .2s);
}

.translate-show() {
    transform: none;
}

.attribute-common() {
    display: block;
    text-transform: uppercase;
    font-weight: 900;
}

.attribute-label() {
    .attribute-common();
    color: @text-3;
    font-size: 10px;
}

.attribute-value() {
    .attribute-common();
    color: @navy;
    font-size: 14px;
}

.link-hidden {
    opacity: .1;
    pointer-events: none;
    background-color: transparent;
}

.overlay {
    position: fixed;
    top: 0;
    right: 0;
    opacity: 0;
    transition: opacity @transition-short;
}

.overlay-visible {
    bottom: 0;
    left: 0;
    opacity: .85;
    background-color: @black;
    z-index: 101;
}

.grid-item {
    padding: @padding-mobile @padding-mini;
}

.transition(@transString: 0) when not (@transString =0) {
    transition: @transString;
}

.nobr {
    white-space: nowrap;
}

.thumbnail {
    border: 1px solid @rgba-black;
    width: 70px;
    height: 70px;
    margin: @padding-mini;
    transition: border-color @transition-short;

    &.active,
    &:hover {
        border-color: @text-2;
    }

    a {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        width: 100%;
        padding: @padding-mini;
    }

    img {
        max-height: 100%;
        max-width: 100%;
        width: auto;
        height: auto;
        display: block;
        margin: auto;
    }

    .minW(@desktop-lg, {
            width: 100px;
            height: 80px;
        }

    );
}

.content {
    .container;
    padding-top: @padding-tablet;
    padding-bottom: @padding-tablet;
    background-color: @white;

    h2 {
        font-size: 1.8rem;
        margin-bottom: @padding-tablet;
    }

    .minW(@desktop-lg, {
            padding-top: @padding-desktop;
            padding-left: @padding-tablet;
            padding-right: @padding-tablet;
            margin-top: @padding-tablet;

            .std {
                font-size: 1.6rem;
            }
        }

    );
}

.text-red {
    color: @red;
}

.text-navy {
    color: @navy;
}

.maxW(@maxWidth, @rules) {
    @media (max-width: @maxWidth) {
        @rules();
    }
}

.minW(@minWidth, @rules) {
    @media (min-width: @minWidth) {
        @rules();
    }
}

.minMax(@minWidth, @maxWidth, @rules) {
    @media (min-width: @minWidth) and (max-width: @maxWidth) {
        @rules();
    }
}

.maxH(@maxHeight, @rules) {
    @media (max-height: @maxHeight) {
        @rules();
    }
}

.minH(@maxHeight, @rules) {
    @media (min-height: @maxHeight) {
        @rules();
    }
}

.nav-button-link() {
    height: @menu-height - 12px;

    .minW(@desktop-lg, {
            height: @menu-height-lg - 34px;
            font-size: 2rem;
        }

    );
}

.cart-item-qty-edit(@height: 36px) {
    display: flex;
    align-items: flex-end;

    .qty-edit {
        width: 34px;
        height: @height;
        display: block;
        text-align: center;
        line-height: calc(~"@{height} - 2px");
        border: 1px solid @text-4;
        font-size: 2rem;
        cursor: pointer;
    }

    .qty-substract {
        border-bottom-left-radius: 30px;
        border-top-left-radius: 30px;
    }

    .qty-add {
        border-bottom-right-radius: 30px;
        border-top-right-radius: 30px;
    }
}

.cart-item-qty-edit-input(@height: 36px) {
    min-height: @height;
    height: 100%;
    max-width: 60px;
    border: 1px solid @text-3;
    padding: 0;
    appearance: textfield;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
}

.button-loader(@backgroundColor) {
    background-color: @backgroundColor;
    opacity: 1;
    position: relative;

    span {
        visibility: hidden;
    }

    &:after {
        content: '';
        position: absolute;
        top: calc(50% - 9px);
        left: calc(50% - 9px);
        width: 18px;
        height: 18px;
        border: 2px solid rgba(255, 255, 255, .6);
        border-top-color: @white;
        border-radius: 50%;
        animation: loading 1s ease infinite;
    }
}

@keyframes loading {
    to {
        transform: rotate(1turn);
    }
}
