.tabs-container {
	.tab-content:not(.active) {
		display: none;
	}
}

.tabs-menu {
	display: flex;
	align-items: center;
	justify-content: center;
	list-style: none;
	line-height: 1.2;
	font-size: 1.2rem;

	.active {
		text-decoration: underline;
	}

	.tabs-menu__item {
		padding: 0 @padding-mini;
		text-align: center;
		cursor: pointer;
		transition: opacity @transition-short;
		&:not(.active) {
			opacity: .4;
		}
	}
}

.tab-content {
    padding-top: @padding-mobile;
    overflow: hidden;

	.std {
        overflow: auto;
        color: @text-2;

		ul {
			text-transform: none;
			color: @text-2;
		}
	}
}

.toggle-tabs {
	border-bottom: 1px solid @rgba-black;
	line-height: 1.2;
	font-weight: 900;
	text-transform: uppercase;
	font-size: 1.2rem;
	color: @product-tab;

	.owl-stage {
        margin: auto;
        touch-action: manipulation;
    }

	.owl-item {
        overflow: hidden;
	}

	.tab-item {
		padding: @padding-mobile;
        margin: 0;
		position: relative;
        cursor: pointer;
        width: 100%;
		height: 100%;
		min-height: 62px;
		display: flex;
		align-items: center;
		justify-content: center;
        text-align: center;

        .minW(@ipad-v, {
            width: 160px;
        });

		span {
            display: block;

            span {
                display: inline;
            }
        }

		&:after {
			content: '';
			position: absolute;
			bottom: -1px;
			left: 0;
			right: 0;
			border-bottom: 2px solid @red;
			transform: scaleX(0);
			.transition(transform .3s);
		}

        .minW(@desktop-min, {
            &:hover {
                color: @red;
                background-color: @category-active-bg;

                &:after {
                    transform: scaleX(1);
                }
            }
        });

        &.current {
            color: @red;
            background-color: @category-active-bg;

            &:after {
                transform: scaleX(1);
            }
        }
	}
}

.collateral-tabs {
	dt {
		.screen-readers;
	}

	.tab,
	.tab-container {
		display: none;

		&.current {
			display: block;
		}
	}
}

.maxW(@mobile-md, {
    .toggle-tabs {
        .tab-item {
            padding: @padding-mobile @padding-mini;
            font-size: 1.1rem;
        }
    }
});
