.header-minicart {
    position: absolute;
    top: 0;
    right: -@padding-mobile;
    bottom: 0;
    width: @minicart-width;
    max-width: calc(~"100% + @{padding-mobile}*2");

    .maxW(@tablet-max, {
            .skip-cart {
                margin-right: 105px;
                width: @menu-height - 12px;
                justify-content: center;

                .minicart-open & {
                    margin-right: 0;
                    width: auto;
                }

                .label {
                    display: none;
                }
            }
        }

    );

    .minW(@mobile-max, {
            right: -@padding-tablet;
        }

    );

    .minW(@desktop-lg, {
            right: -@padding-desktop;

            .minicart-open & {
                width: @minicart-width-lg;
            }
        }

    );

    p {
        margin-top: @padding-mobile;
    }

    .close-minicart {
        font-size: 3rem;
        display: none;

        &:hover,
        &:focus,
        &:active {
            border: 0;
        }
    }

    .minicart-open & {

        >.active {
            overflow-y: auto;
            height: 100vh;
            position: relative;

            .skip-link {
                transform: none;

                top: 6px;

                .minW(@desktop-lg, {
                        top: 17px;
                    }

                );
            }
        }

        .close-minicart {
            .align-v();
            display: block;
            z-index: 6;
            left: @padding-mobile;
        }
    }

    .menu-open & {
        border-left: 0;
    }

    .skip-link {
        z-index: 5;
    }


    .skip-content {
        top: 0;
        position: absolute;
        overflow-x: hidden;
        min-height: 100vh;
        width: 500px;
        max-width: 100%;

        &:before {
            content: '';
            z-index: 1;
            position: relative;
            display: block;
            border-bottom: 1px solid @border-light;
            height: @menu-height;
            margin-left: -@padding-mobile;
            margin-right: -@padding-mobile;
            top: -@padding-mobile;
        }

        .minW(@desktop-lg, {
                &:before {
                    height: @menu-height-lg;
                }

            }

        );
    }
}

.skip-cart {
    .align-v();
    right: @padding-mobile;
    .button-red-outline();
    .nav-button-link();

    .qty {
        display: none;
    }

    .icon+.label {
        padding-left: 1rem;
    }

    .minW(@mobile-max, {
            right: @padding-tablet;
        }

    );

    .maxW(@desktop-sm, {
            .label.visible-desktop {
                display: none;
            }
        }

    );

    .label {
        padding: 0 @padding-mini;

        .minicart-open & {
            display: block;
        }
    }

    .price {
        font-weight: 900;
    }

    .menu-open & {
        display: none;
    }
}

.minicart-open {
    background-color: #252525;

    .overlay {
        position: absolute;
        .overlay-visible;
        width: calc(~"100% - @{minicart-width}");
        right: auto;

        .minW(@desktop-lg, {
                width: calc(~"100% - @{minicart-width-lg}");
            }

        );
    }

    .minicart-order {
        width: @minicart-width;

        .minW(@desktop-lg, {
                width: @minicart-width-lg;
            }

        );
    }
}
