.newsletter-consent {
    .more-button {
        .button-dark;
        float: right;
        padding: 0 5px !important;
        cursor: pointer;
        margin-top: @padding-mobile;
        margin-bottom: @padding-mobile;
    }

    .more {
        display: none;
        clear: both;
    }
}