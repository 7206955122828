.account {

    .form-address-edit,
    .form-edit-account,
    .form-newsletter-manage,
    .orders-history {
        .content;

        .legend {
            .h3;
            color: @red;
        }

        .actions-toolbar .action.primary {
            .button-dark;
        }

        .actions .action {
            .button;
        }
    }

    &.page-layout-2columns-left {
        .sidebar-main {
            display: block;

            .minMax(@ipad-v, @tablet-max, {
                    padding-right: 0;
                }

            );

            .maxW(@ipad-v - 1, {
                    .block-collapsible-nav {
                        top: -42px;
                        padding-top: 0;
                        background-color: #f5f5f5;

                        .content {
                            border-radius: 0;
                        }
                    }
                }

            );
        }
    }

    .main {
        .block-title {
            z-index: 1;
            padding-left: 0 !important;

            strong,
            a {
                padding: @padding-mobile @padding-mobile + @padding-mini;
                font-weight: 700 !important;
                font-size: 1.8rem;
                text-align: center;
                text-decoration: none;
                margin: 0 !important;
            }

            strong {
                border-top-left-radius: @radius;
                border-top-right-radius: @radius;
                background-color: @white;
                box-shadow: 0px 5px 50px rgba(96, 96, 96, .14);
                color: @red;
            }
        }

        .block-content {
            z-index: 2;
            border-top-right-radius: @radius;
            margin-top: -2px;
        }
    }

    .table-wrapper {
        .content {
            padding: 0;
            background-color: transparent;
        }
    }

    .block-collapsible-nav {
        .block-collapsible-nav-title {
            background-color: @main-bg;
        }

        .content {
            border-radius: @radius;
        }

        .item.current {

            a,
            >strong {
                border-color: @red;
                border-width: @border-thick;
                color: @navy;
                font-weight: 700;
            }
        }

    }

    .box-actions {
        margin-top: @padding-mobile;

        .action {
            margin-right: @padding-mobile;
            .button-navy-outline;
        }
    }

    .table-order-items {
        .actions .action {
            .button-navy-outline;
            margin: @padding-mini;
        }
    }

    .field.choice {
        padding-bottom: 0;
    }

    .block-dashboard-orders,
    .block-addresses-list {
        .block-content {
            padding-top: @padding-mobile * 2;

            &:before {
                content: none;
            }
        }
    }

    .minW(@ipad-v, {
            .table-wrapper {
                border: 1px solid @border-light;
                overflow: auto;
            }
        }

    );

    .form-edit-account {
        .fieldset.info {
            .field.choice:not(.login-as-customer) {
                display: inline-flex;
                align-items: baseline;

                .label {
                    .button-navy-outline;
                    width: auto;
                    margin-right: @padding-mobile;

                    &:before {
                        content: none;
                    }
                }

                input[type=checkbox]:checked~.label {
                    color: @navy;
                    border-color: @navy;
                }

                #change-password,
                #change-email {
                    display: none;
                }
            }
        }
    }

    .form-newsletter-manage {
        .field.choice {
            .label {
                display: flex;
                padding: @padding-lg;
                padding-left: @padding-lg + @padding-tablet;
                border: 1px solid @border-light;
                margin-bottom: @padding-mobile;
                color: @border-light;
                max-width: 600px;

                &:before {
                    margin-top: @padding-lg;
                    margin-left: @padding-lg;
                    border-color: @border-light;
                }
            }

            input[type=checkbox]:checked~.label {
                border-color: @navy;
                color: @text-1;
                background-color: @main-bg;
            }

            &:before {
                content: none;
            }
        }
    }

    .fieldset {
        &.password {
            .field.choice {
                display: flex;
                width: 100%;

                label[for=show-password] {
                    color: @text-3;
                    margin-left: auto;
                    width: auto;

                    &:before {
                        border-color: @text-3;
                    }
                }

                input[name=show-password]:checked+label:before {
                    background-color: @text-3;
                    border-color: @text-3;
                }
            }
        }

        >.field>.label,
        .fields>.field>.label {
            font-weight: 500;
        }
    }
}

.order-products-toolbar {
    margin-top: @padding-mobile;

    .pager {
        display: flex;
        align-items: center;
        width: 100%;

        .maxW(@tablet-max, {
                justify-content: center;
                flex-wrap: wrap;
            }

        );

        .toolbar-amount {
            width: auto;
            padding: 0;
        }

        .limiter {
            width: auto;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .limiter-options {
            width: 80px;
        }
    }

    .minW(@ipad-v, {
            &.toolbar .pager .pages {
                position: relative;
            }
        }

    )
}

.customer-trader-info {
    font-weight: 700;
    font-size: 1.8rem;
    padding-top: @padding-mobile * 2;

    .accent {
        color: @red;
    }
}

.my-account {
    .container;
    position: relative;
    z-index: 10;
    padding-top: 2rem;
    padding-bottom: @padding-tablet;
    background-color: @white;
    border-radius: @radius;
    margin-top: 0;

    .page-title-wrapper {
        margin-top: @padding-mobile;
    }

    form {
        font-size: 1.2rem;
    }

    .box {
        font-size: 1.2rem;
    }

    p {
        font-size: inherit;
    }

    .form-input,
    .input-text,
    .select2-container--default .select2-selection--single {
        &:not(.validation-failed) {
            border-color: @border-light;
            color: @text-2;
        }
    }

    .checkbox-styled label {
        color: @text-2;
    }

    .checkbox-styled label:before,
    .checkbox-styled input:checked+label:before {
        border-color: @text-1;
    }

    .checkbox-styled input[type='radio']:checked+label:before {
        background-color: @text-1;
    }

    .checkbox-styled input[type='checkbox']:checked+label:before {
        background-color: @white;
    }

    h2 {
        font-size: 1.8rem;
    }

    .box,
    .box-account {
        font-size: 1.2rem;
    }

    .form-list {
        line-height: 1.2;

        .label,
        label {
            font-weight: 700;
            color: @text-1;
            font-family: @font-1;
        }

        .password-strength {
            margin: @padding-mobile 0 @padding-mini;

            label {
                color: @text-3;
                font-weight: 900;
            }
        }

        li {
            margin: @padding-mini 0;
        }

        .input-box {
            margin: @padding-mini 0;
            padding: 0;
        }

        .checkbox-styled {
            input[type='radio'] {
                +label {
                    flex: 0;
                    padding-right: @padding-tablet;
                    display: flex;
                    align-items: center;
                    height: 20px; // IE 11 vertical align patch

                    &:before {
                        .align-v();
                    }
                }
            }
        }
    }

    .control {
        .checkbox-styled;
        padding-bottom: 0;
    }

    .toggle-password {
        padding-top: 2rem;
        padding-bottom: 0;
    }

    .field {
        margin-top: @padding-mobile;
    }

    .link-print {
        .button-dark;
        .button-small;
        padding-left: 44px;
        position: relative;

        .icon {
            height: 16px;
            .align-v();
            left: 1rem;
        }
    }

    .link-back {
        .button;
        .button-small;
        position: relative;
        padding-left: 44px;

        .icon {
            height: 11px;
            .align-v();
            left: 1rem;
        }
    }

    .buttons {

        .button,
        .link-back {
            margin-right: 1rem;
            margin-left: 0;
        }
    }

}

.box {
    border: 1px solid @border-light;
    padding: 2rem @padding-mobile 2rem;
    margin-top: 4rem;

    .box-title {

        h1,
        h2 {
            font-size: 1.8rem;
            margin-top: -1.5em;
            background-color: @white;
            float: left;
            box-shadow: 10px 0 @white, -10px 0 @white;
        }
    }

    .box-content {
        clear: both;

        >* {
            margin-top: 0;
        }

        >p {
            margin-top: @padding-mobile;
        }
    }

    .box-footer {
        margin-top: @padding-mobile;
        display: flex;
        justify-content: flex-end;
    }
}

.newsletter-manage-index {
    .control {
        label {
            font-size: 1.6rem;
        }
    }
}

.buttons-set {
    margin-top: @padding-mobile;

    .button {
        margin-right: @padding-mini * 2;
    }
}

.table {
    overflow-x: auto;
    max-width: 100%;

    table {
        margin-bottom: 0;
        border: 0;
    }
}

.data.table {
    width: 100%;
    line-height: 1.2;
    margin: 0;

    thead {
        border-bottom: 1px solid @border-light;
    }

    th,
    td {
        border: 0;
        padding: @padding-mini;
        vertical-align: middle;
    }

    td.actions {
        padding-left: 0;
        padding-right: 0;
    }

    tr {
        &:not(:last-child) {
            border-bottom: 1px solid @border-light;
        }
    }

    .status-label {
        white-space: nowrap;
        font-weight: 900;
    }
}

.order-cancelled {
    .status-label {
        color: @error;
    }
}

.order-processing {
    .status-label {
        color: @yellow-dark;
    }
}

.order-complete {
    .status-label {
        color: @success;
    }
}

.order-pending-payment {

    td {
        &:first-child {
            position: relative;

            &:before {
                content: '';
                position: absolute;
                top: -1px;
                bottom: -1px;
                left: 0;
                border-right: 6px solid @error;
            }
        }
    }

    .status-label {
        color: @error;

        &::before {
            content: '!';
            display: inline-block;
            vertical-align: middle;
            margin-right: @padding-mini;
            width: 20px;
            height: 20px;
            border-radius: 50%;
            text-align: center;
            line-height: 18px;
            font-size: 16px;
            font-weight: 500;
            color: @white;
            background-color: @error;
        }
    }
}

.addresses-list {
    .list-reset;

    h3 {
        font-family: @font-1;
        font-size: 1.2rem;
        margin-bottom: .5rem;
        width: 100%;
    }

    li {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        &:not(:last-child) {
            border-bottom: 1px solid @border-light;
            padding-bottom: 2rem;
            margin-bottom: 2rem;
        }
    }
}

.box-addresses {
    .box-footer {
        border-top: 1px solid @border-light;
        justify-content: flex-start;
        padding-top: 2rem;
    }
}

.box-recent {
    border: 1px solid @border-light;
    border-top: 0;

    .box-head {
        margin-top: 4rem;
        min-height: 4rem;
        border-bottom: 1px solid @border-light;
        border-top: 1px solid @border-light;

        h2 {
            margin-top: -.75em;
            margin-left: @padding-mobile;
            background-color: @white;
            display: inline-block;
            vertical-align: text-top;
            box-shadow: 10px 0 @white, -10px 0 @white;
        }
    }

    .box-footer {
        text-align: center;
        padding-top: 2rem;
        padding-bottom: 2rem;
        border-top: 1px solid @border-light;
    }

    table {
        margin-top: 0;
    }
}

.newsletter-manage-index {
    .buttons-set {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
}

.maxW(@mobile-max, {
        .account-tabs {

            li {
                width: 50%;
            }

            .current {
                box-shadow: 0 @radius @white, 0px 5px 50px rgba(96, 96, 96, .14);

                &:nth-child(1) {
                    order: 2;

                    + li {
                        order: 1;

                        + li {
                            order: 0;

                            + li {
                                order: 4;
                            }
                        }
                    }
                }

                &:nth-child(2) {
                    order: 4;

                    + li {
                        order: 3;
                    }
                }
            }
        }

        .my-account {
            .form-list {
                li {
                    &:not(:last-child) {
                        margin-bottom: @padding-mobile;
                    }

                    .label, label {
                        display: block;
                        margin-bottom: @padding-mini;
                    }
                }
            }

            .buttons {
                display: flex;
                align-items: flex-start;
                flex-wrap: wrap;

                .button {

                    &:not(:last-child) {
                        margin-bottom:@padding-mobile;
                    }
                }

            }
        }

        .addresses-list {

            address {
                width: 100%;
            }

            .buttons {
                margin-top: @padding-mobile;
            }
        }

    }

);

.maxW(@ipad-v, {

        .account {
            .block-content {
                padding-top: @padding-mobile;
            }
        }
    }

);

.maxW(@tablet-md, {
        .order-pending-payment {

            td {
                position: relative;

                &:before {
                    content: '';
                    position: absolute;
                    top: -1px;
                    bottom: -1px;
                    left: 0;
                    border-right: 6px solid @error;
                }
            }
        }
    }

);

.minW(@mobile-max, {

        .my-account {
            .form-list {
                li {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    flex-wrap: wrap;
                    margin: 0;

                    > label, .label {
                        width: 200px;
                    }

                    > .input-box {
                        width: calc(~"100% - 200px - @{padding-mobile}");
                    }

                }

                .checkbox-styled, .control {
                    label {
                        width: 100%;
                    }
                }

                .fields {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;


                    .field {
                        width: calc(~"50% - @{padding-mobile}");
                    }
                }
            }

            .password-strength {
                padding-left: calc(~"200px + @{padding-mobile}");
            }

            .box-info {
                .form-list {
                    li {
                        margin: 2rem 0;

                        > * {
                            width: calc(~"50% - @{padding-mini}");
                        }
                    }
                }
            }
        }

        .addresses-list {
            .buttons {
                .button {
                    margin-left: 1rem;
                    margin-right: 0;
                }
            }
        }
    }

);

.minW(@ipad-v, {
        .box-info {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;

            .box-head {
                width: 100%;
            }

            .col2-set {
                width: calc(~"50% - @{padding-mobile}");
            }
        }

        .data.table {
            th, td {
                border: 0;
                padding: @padding-mini*2;
            }
        }

        .form-edit-account {
            .fieldset.info {
                .field.taxvat+.field.choice {
                    margin-left: 25.8%;

                    &:before {
                        content: none;
                    }
                }
            }
        }

    }

);

.minW(@desktop-min, {
        .box-recent {
            .box-head {
                h2 {
                    margin-left: @padding-tablet;
                }
            }
        }
    }

);