.form-outline {
    input {
    &[type='search'] {
        .form-input();
        font-weight: 600;
        .transition(background-color .2s);
        padding-right: 40px;
    }

    &[type='text'],
    &[type='password'],
    &[type='url'],
    &[type='search'],
    &[type='tel'],
    &[type='zip'],
    &[type='number'],
    &[type='date'],
    &[type='email'] {
        color: @black;
        border: 1px solid @black;
        padding: 1rem;
        border-radius: @radius-sm;
        height: auto;
        min-height: 2rem;

        &::placeholder {
            color: @black;
        }

        &.mage-error {
            &::placeholder {
                color: @error;
            }
        }
    }
    }
    .field .label {
        .screen-readers;
    }
    .control,
    .fieldset > .field:not(.choice) > .control {
        width: 100%;
    }

    .minW(@desktop-min, {
        .fieldset > .field {
            margin-bottom: 1rem;
        }
    });
}
