.icon {
    max-height: 30px;
    max-width: 30px;
    height: auto;
    // width: auto;
    stroke: @icon-light;
    transition: stroke @transition;

    .minW(@desktop-lg, {
            max-height: 38px;
            max-width: 38px;
        }

    );
}

svg {
    path {
        stroke: inherit;
    }
}

.icon-large {
    max-height: 100%;
    max-width: 100%;
}

.icon-arrow {
    height: 7px;
}

.icon-cart {
    height: 21px;
    max-width: 39px;
}

.icon-cart-red {
    height: 20px;
    width: 20px;
}

.icon-user {
    height: 20px;
    width: 20px;
}

.icon-arrow-small {
    .icon;
    height: 13px;
    width: 13px;
}

.icon-arrow-down {
    height: 20px;
    width: 20px;
}

.icon-arrow-right {
    transform: rotate(180deg);
}

.icon-search {
    .icon;
    height: 20px;
    width: 20px;
    stroke: @black;
    stroke-width: 2;

    &:hover {
        stroke: @red;
    }
}

.icon-items {
    .icon-large();
    height: 65px;
}

.icon-delivery {
    .icon-large();
    height: 50px;
    fill: @text-1;
}

.icon-safety {
    .icon-large();
    height: 75px;
}

.icon-advice {
    .icon-large();
    height: 70px;
}

.icon-close {
    width: 10px;
    height: 10px;
    stroke: @red;
}

.icon-promotion {
    width: 72px;
    height: 72px;
    border-radius: 50%;
    background-color: @icon-red;
    .icon('design-scissor');
    background-size: 35px auto;
    background-position: center;
}

.icon-products {
    width: 20px;
    height: 20px;
}

.icon-delivery-installation {
    max-width: 46px;
    max-height: 26px;
}

.icon-arrow-back-long {
    height: 18px;
    width: 24px;
}

.icon-fb {
    stroke: @navy;
    fill: @navy;
}