.section-banner {
    font-size: 1.6rem;

    .article {
        .container;
        margin: 0;
        color: @text-2;
        line-height: 1.6;
        font-size: 14px;

        [class^='button'] {
            margin-top: @padding-mobile;
        }

        p {
            font-size: 14px;
        }

        .minW(@desktop-min, {
                width: 50%;
            }

        );

        .minW(@desktop-lg, {
                width: 700px;
            }

        );
    }

    .banner-header {
        margin-bottom: @padding-mobile;
        color: @banner-bg;

        .minW(@desktop-lg, {
                font-size: 5.6rem;
            }

        );

        .maxW(@tablet-md, {
                padding-right: 0;
            }

        )
    }
}

.section-banner-dark {
    background-color: @banner-bg;
    color: @white;

    p,
    a {
        color: @white;
    }

    .article {
        color: @white;
    }

    .banner-header {
        color: @white;
    }

    .slider-progress-nav {

        .owl-prev,
        .owl-next {
            border-color: @white;

            .icon {
                stroke: @white;
            }

        }
    }

    .button-red {

        &:active,
        &:focus,
        &:hover {
            color: @white;
        }
    }
}

.banner-catalog {
    background-repeat: no-repeat;
    background-image: url('../images/banners/banner-spring-md.jpg');
    background-size: cover;
    background-position: center;
    min-height: 280px;
    display: flex;

    .minW(@desktop-md, {
            background-image: url('../images/banners/banner-spring-lg.jpg');
        }

    );

    .minW(@desktop-lg, {
            font-size: 2rem;
            min-height: 380px;
        }

    );

    .maxW(@tablet-max, {
            background-image: url('../images/banners/banner-spring-sm.jpg');
            min-height: 300px;
        }

    );

    .article {
        padding-top: @padding-lg;
        padding-bottom: @padding-lg;
        max-width: 60vw;
        width: 900px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;

        strong {
            display: block;
        }

        .maxW(@mobile-max, {
                max-width: 80vw;
            }

        );
    }

    .banner-header {
        font-family: @font-banner;
        text-transform: uppercase;
        font-size: 3.2rem;
        font-weight: 400;
        line-height: 1.1;

        .minW(@mobile-max, {
                font-size: 6rem;
            }

        );

        .minW(@desktop-sm, {
                font-size: 7rem;
            }

        );

        .minW(@desktop-lg, {
                font-size: 8rem;
            }

        );
    }

    .button-yellow {

        &:active,
        &:focus,
        &:hover {
            color: @yellow  !important;
        }
    }
}

.banner-about {
    display: flex;
    flex-direction: column;
    background-color: @white;
    padding-top: @padding-lg;
    padding-bottom: @padding-lg;

    .minW(@tablet-md, {
            flex-direction: row;
            align-items: center;

            .article, .banner-image {
                flex: 1;
            }
        }

    );

    .minW(@desktop-min, {
            flex-direction: row;
            justify-content: stretch;

            .article {
                width: 40vw;
            }

            .banner-image {
                width: 60vw;
            }
        }

    );

    .minW(@desktop-lg, {
            .article {
                flex: .75;
            }

            .banner-image {
                flex: 1.25;
            }
        }

    );

    .article {
        max-width: 100%;
        padding-bottom: @padding-tablet;

        .minW(@mobile-max, {
                padding-bottom: @padding-lg;
            }

        );

        .minW(@tablet-md, {
                padding-bottom: 0;
            }

        );

        .minW(@desktop-lg, {
                padding-bottom: @padding-lg*2;
            }

        );

        .maxW(@tablet-md, {
                text-align: center;
            }

        );
    }

    .banner-image {
        .container;
        margin: 0;
        text-align: center;
    }

    .button {
        .minW(@tablet-md, {
                margin-top: @padding-tablet;
            }

        );
    }
}

.banner-recommended {
    .container;
    padding-top: @padding-lg;
    padding-bottom: @padding-lg;
    position: relative;

    .maxW(@tablet-max, {
            .banner-header {
                min-height: 50px;
            }
        }

    );

    .minW(@tablet-md, {
            display: flex;
        }

    );

    .minW(@desktop-min, {
            padding-bottom: 0;
        }

    );

    .article {
        padding-bottom: @padding-lg;
        padding-left: 0;
        padding-right: 0;

        .minW(@tablet-md, {
                width: 50%;
            }

        );

        .minW(@desktop-min, {
                width: 33%;
            }

        );


        .maxW(@tablet-md, {
                text-align: center;
            }

        )
    }

    .slider-recommended {

        .minW(@tablet-md, {
                width: 50%;
            }

        );

        .minW(@desktop-min, {
                width: 67%;
                padding-left: 2rem;
            }

        );

        .maxW(@tablet-md, {
                padding-bottom: 6rem;
            }

        )
    }

    .slider-progress-nav {
        margin-bottom: 2rem;
    }
}

.banner-articles {
    .container;
    padding-top: @padding-lg;
    padding-bottom: @padding-lg;
    position: relative;
    background-color: @navy;
    color: @white;
    min-height: 320px;

    .maxW(@tablet-max, {
            .banner-header {
                min-height: 50px;
            }
        }

    );

    .minW(@tablet-md, {
            display: flex;
        }

    );

    .minW(@desktop-min, {
            padding-bottom: 0;
        }

    );

    .maxW(@mobile-max, {
            .slider-progress-nav {
                right: @padding-mobile;
            }
        }

    );

    .article {
        display: flex;
        flex-direction: column;
        padding-bottom: @padding-lg;
        padding-left: 0;
        padding-right: 0;

        .minW(@tablet-md, {
                width: 50%;
            }

        );

        .minW(@desktop-min, {
                width: 33%;
            }

        );


        .minMax(@mobile-max, @tablet-md, {
                text-align: center;
            }

        );

        .maxW(@tablet-max, {
                text-align: left;

                .button {
                    position: absolute;
                    bottom: @padding-mobile * 2;
                    left: @padding-mobile;
                }
            }

        );

        .minMax(@mobile-max, @tablet-max, {
                .button {
                    left: @padding-mobile *2;
                }
            }

        );

        .minMax(@tablet-md, @tablet-max, {
                .button {
                    margin-bottom: 1rem;
                }
            }

        );

        .button {
            margin-top: auto;
            margin-right: auto;

            .icon {
                margin-left: 1rem;
            }
        }
    }

    .slider {

        .minW(@tablet-md, {
                width: 50%;
            }

        );

        .minW(@desktop-min, {
                width: 67%;
                padding-left: 2rem;
            }

        );

        .maxW(@tablet-md, {
                padding-bottom: 6rem;
            }

        )
    }

    .post-holder {
        display: flex;
        flex-direction: column;
        padding-bottom: 0;
    }

    .post-header {
        order: 2;
        padding-top: @padding-mobile;
    }

    .post-title {
        text-transform: uppercase;
        text-decoration: none;
        color: @white;
        font-size: 90%;
    }

    .post-content {
        position: relative;

        .button {
            opacity: 0;
            .align();
            z-index: 3;
        }

        &:before {
            content: '';
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            background-color: @navy;
            opacity: 0;
            z-index: 2;
            transition: opacity @transition-short;
            pointer-events: none;
        }

        &:hover {
            cursor: pointer;

            .button {
                opacity: 1;
            }

            &:before {
                opacity: 0.8;
            }
        }
    }

}

.slider-progress-nav {
    display: flex;
    align-items: center;
    position: absolute;

    .maxW(@tablet-md, {
            bottom: @padding-tablet;
            right: calc(50% - 115px/2);
            z-index: 5;
        }

    );

    .minW(@tablet-md, {
            bottom: @padding-lg;
            right: 67%;
        }

    );

    .minMax(@tablet-md, @tablet-max, {
            bottom: @padding-lg;
        }

    );

    .owl-prev {
        margin-right: @padding-mobile;
    }

    .owl-prev,
    .owl-next {
        width: @slider-progress-nav-size;
        height: @slider-progress-nav-size;
        border-radius: 50%;
        border: 4px solid @text-4;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: border-color @transition;
        cursor: pointer;
        position: relative;

        .icon {
            stroke: @text-1;
            height: 15px;
            width: 10px;
            margin-left: -2px;
            transition: stroke @transition-short;
        }

        &.disabled {
            .icon {
                stroke: @text-4;
            }
        }
    }

    .owl-next {
        .icon {
            transform: rotate(180deg);
            margin-left: 2px;
        }
    }
}

.icon-progress {
    transform: rotate(-90deg);
    position: absolute;
    top: -@progress-border;
    left: -@progress-border;

    circle {
        stroke-dashoffset: 0;
        transition: stroke-dashoffset @transition;
        stroke-width: @progress-border;
        fill: transparent;
        stroke-linecap: round;
    }

    .owl-next & {
        .bar {
            stroke: @red;
            stroke-linecap: round;
        }
    }
}