.cms-page-view {
    .std {
        .container;
        padding-top: @padding-tablet;
        padding-bottom: @padding-tablet;
        background-color: @white;

        a {
            text-decoration: underline;
        }
    }
}

.minW(@desktop-lg, {
        .cms-page-view {
            .std {
                padding-top: @padding-desktop;
                margin-top: @padding-tablet;
            }
        }
    }

);