.post-list {
  .list-reset;
  padding-top: @padding-mobile;

  a {
    text-decoration: none;

    &:hover,
    &:active,
    &:focus {
      color: @text-1;
    }
  }

  .post-holder {
    background-color: @white;
    border-radius: @radius-sm;
    border-width: 0;
    width: 360px;
    max-width: 100%;
    margin: @padding-tablet auto;
    box-shadow: 0 0 1px @category-item-shadow;
    transition: box-shadow @transition, border @transition;
    padding: 0 @padding-mobile @padding-mobile;

    img {
      backface-visibility: hidden;
      display: block;
      width: auto;
      margin: auto;
      max-height: 100%;
      transition: opacity @transition-short;
    }

    &:hover {
      box-shadow: 0 20px 15px -15px @rgba-shadow;
      border-color: @white;

      img {
        opacity: .7;
      }
    }
  }

  .button {
    .button-dark();
    .button-small();

    &:hover {
      border-color: @yellow;
      background-color: @yellow;
      color: @text-1;
    }
  }

  .post-header {
    .post-title {
      text-transform: uppercase;
      padding: @padding-mobile 0;
      font-weight: 900;
      font-size: 1.2rem;
      max-width: 100%;
      line-height: 1.4;
      float: none;
    }
  }

  .article-image {
    margin: 0;
    height: 140px; // lg 180px
    position: relative;
    overflow: hidden;
  }

  .post-text-hld.article-short {
    padding: @padding-mobile 0 0;
    height: 120px;
    overflow: hidden;
    color: @text-1;
  }

  .post-footer {
    margin-top: 10px;
    padding-top: @padding-mini;
    text-align: right;
  }
}

.minW(@ipad-v, {
  .post-list {
    display: flex;
    flex-wrap: wrap;
    margin-left: -@padding-mobile;
    margin-right: -@padding-mobile;

    .post-title {
      height: 70px;
      display: flex;
      align-items: center;
      overflow: hidden;
      line-height: 1.2;
    }

    .post-holder {
      margin: @padding-mobile;
      width: calc(~"50% - @{padding-mobile} * 2");
    }
  }

});

.minW(@tablet-max, {
  .post-list {
    .post-holder {
      margin: @padding-mobile;
      width: calc(~"33.33% - @{padding-mobile} * 2");
    }
  }
});

.minMax(@desktop-min, @desktop-sm - 1px, {
  .post-list {
    .post-holder {
      margin: @padding-mobile;
      width: calc(~"50% - @{padding-mobile} * 2");
    }
  }
});

.minW(@desktop-lg, {
  .post-list {
    .article-image {
      height: 180px;
    }

    .button {
      padding: .8rem 2.6rem;
      font-size: 1.2rem;
    }

    .post-title {
      padding: @padding-mobile 0;
    }
  }
});

.maxW(@ipad-v, {
  .post-list {
    .post-holder {
      .post-description {
        display: block;
      }
    }
  }
});
