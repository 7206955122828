html {
    font-size: 62.5%;
}

body {
    font-size: 1.4rem;
    font-family: @font-1;
    line-height: 1.6;
    color: @text-2;
    background-color: @main-bg;
    overflow-x: hidden;
}

.menu-open,
.minicart-open,
.account-open {
    overflow: hidden;
    position: relative;

    .main-container {
        position: relative;

        &:before {
            content: '';
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
            height: @menu-height;
            background-color: @white;
            z-index: 5;

            // .minW(@desktop-md, {
            // 	height: @menu-height-lg;
            // });
        }
    }
}


.menu-open,
.account-open {
    .overlay {
        .overlay-visible;
        z-index: 10;
    }
}

*,
:before,
:after {
    box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
    font-weight: 700;
    margin: 0 0 .67em 0;
    font-family: @font-1;
    line-height: 1.2;
    color: @navy;
}

h1,
.h1 {
    font-size: 2.2rem;

    .minW(@mobile-max, {
            font-size: 2.8rem;
        }

    );

    .minW(@desktop-lg, {
            font-size: 4rem;
        }

    );
}

h2,
.h2 {
    font-size: 2.2rem;

    .minW(@mobile-max, {
            font-size: 2.8rem;
        }

    );

    .minW(@desktop-lg, {
            font-size: 4rem;
        }

    );

}

h3,
.h3 {
    font-size: 2rem;
}

h4,
.h4 {
    font-size: 1.8rem;
}

h5,
.h5 {
    font-size: 1.8rem;
}

h6,
.h6 {
    font-size: 1.6rem;
}

a,
.alink {
    text-decoration: none;
    color: @navy;
    transition: color @transition-short;
    -webkit-tap-highlight-color: transparent;

    &:active,
    &:hover,
    &:focus,
    &:visited {
        outline: 0;
        text-decoration: none;
        border-color: @navy;
        color: @navy;
    }

    &:hover,
    &:active,
    &:focus {
        color: @red;
    }

    &:visited {
        color: @navy;
    }

    main & {
        .content-link();
    }

    &[rel="lightbox"] {
        cursor: zoom-in;
    }
}

strong {
    font-weight: 700;
}

img {
    max-width: 100%;
    height: auto;
}


figure {
    margin: 0;
}

ul {
    margin: 0;
}

p {
    &:first-of-type {
        margin-top: 0;
    }

    &:last-of-type {
        margin-bottom: 0;
    }
}

table {
    text-align: left;
    border-collapse: collapse;
    border: 1px solid @border-light;
    margin-top: @padding-mobile;
    margin-bottom: @padding-mobile;
    width: auto;
}

th {
    padding: 5px 10px;
    background-color: @main-bg;
    color: @text-1;
    border: 1px solid @border-light;
}

td {
    padding: 5px 10px;
    border: 1px solid @border-light;
}

dd {
    margin-left: 0;
}

progress {
    background-color: @gray;
    color: @red;
    border: 0;
    height: 8px;
    border-radius: 4px;
    overflow: hidden;

    &.high {
        color: @success;
    }

    &.medium {
        color: @yellow-dark;
    }
}

progress-bar {
    background-color: @gray;
    border-radius: 4px;
}

progress-value {
    background-color: @red;
    border-radius: 4px;

    .high & {
        background-color: @success;
    }

    .medium & {
        background-color: @yellow-dark;
    }
}

address {
    font-style: normal;
}

fieldset {
    padding: 0;
    border: 0;
}

progress,
/* All HTML5 progress enabled browsers */
progress[role]

/* polyfill */
    {

    /* Turns off styling - not usually needed, but good to know. */
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;

    /* gets rid of default border in Firefox and Opera. */
    border: none;

    /* Needs to be in here for Safari polyfill so background images work as expected. */
    background-size: auto;

    /* Dimensions */
    width: 150px;
    height: 8px;
}

/* Polyfill */
progress[role]:after {
    background-image: none;
    /* removes default background from polyfill */
}

/* Ensure fallback text doesn't appear in polyfill */
progress[role] strong {
    display: none;
}

progress,
/* Firefox  */
progress[role][aria-valuenow] {
    /* Polyfill */
    background: @gray  !important;
    /* !important is needed by the polyfill */
}

/* Chrome */
progress::-webkit-progress-bar {
    background: @gray  !important;
}

/* Firefox */
progress {
    &::-moz-progress-bar {
        background: @red;
        border-radius: 4px;
    }

    &.high {
        &::-moz-progress-bar {
            background: @success;
        }
    }

    &.medium {
        &::-moz-progress-bar {
            background: @yellow-dark;
        }
    }
}

/* Chrome */
progress {
    &::-webkit-progress-value {
        background: @red;
        border-radius: 4px;
    }

    &.high {
        &::-webkit-progress-value {
            background: @success;
        }
    }

    &.medium {
        &::-webkit-progress-value {
            background: @yellow-dark;
        }
    }
}


/* Polyfill */
progress {
    &[aria-valuenow]:before {
        background: @red;
        border-radius: 4px;
    }

    &.high {
        [aria-valuenow]:before {
            background: @success;
        }
    }

    &.medium {
        [aria-valuenow]:before {
            background: @yellow-dark;
        }
    }
}

.clearfix {
    &:after {
        content: '';
        display: table;
        clear: both;
    }
}

.no-display {
    display: none;
}

/* fade image in after load */
.lazyload,
.lazyloading {
    opacity: 0;
}

.lazyloaded {
    opacity: 1;
    transition: opacity 300ms;
}

.value,
.std,
.post-description {
    >*:first-child {
        margin-top: 0;
    }

    h2,
    h3,
    h4,
    h5 {
        font-size: 1.8rem;
    }

    ul,
    ol {
        &:not(.list-unstyled) {
            margin: @padding-mobile 0 @padding-desktop;

            li {
                margin-bottom: @padding-mobile;
            }

            ul,
            ol {
                margin-bottom: @padding-mobile;

                li {
                    margin-bottom: @padding-mini;
                }
            }

            ol {
                list-style-type: lower-alpha;
                padding-left: @padding-mobile;

                >li {
                    padding: 0 0 0 @padding-mobile;
                    text-indent: -@padding-mobile;
                    list-style-type: none;
                    counter-increment: item;

                    &:before {
                        display: inline-block;
                        width: 1rem;
                        padding-right: 1rem;
                        font-weight: 700;
                        color: @text-1;
                        text-align: right;
                        content: counter(item, lower-alpha) ")";
                    }
                }
            }
        }
    }

    ol:not(.list-unstyled) {
        padding-left: 3rem;
        counter-reset: item;

        >li {
            padding: 0;
            text-indent: -@padding-mobile;
            list-style-type: none;
            counter-increment: item;

            &:before {
                display: inline-block;
                width: 1rem;
                padding-right: 1rem;
                font-weight: 700;
                color: @text-1;
                text-align: right;
                content: counter(item) ".";
            }
        }
    }

    ul {
        list-style: none;

        &:not(.list-unstyled) {
            padding-left: 0;

            >li {
                padding: 0 0 0 2rem;
                text-indent: -2rem;

                &:before {
                    content: '';
                    display: inline-block;
                    width: 7px;
                    height: 1rem;
                    margin-right: 1rem;
                    text-align: right;
                    .icon('arrow-small-active');
                    transform: rotate(180deg);
                }
            }
        }
    }
}

.list-unstyled {
    padding-left: 0;
}

.std,
main {
    color: @text-2;
}

.minW(@desktop-min, {

        .value,
        .std,
        .post-description {
            ol {
                &:not(.list-unstyled) {
                    padding-left: @padding-lg;
                }

            }

            ul {
                &:not(.list-unstyled) {
                    padding-left: @padding-mobile;
                }
            }

            ul, ol {

                ul {
                    padding-left: 0;
                }
            }
        }
    }

);

.minW(@desktop-lg, {
        body {
            font-size: 1.6rem;
        }

        .value,
        .std,
        .post-description {

            h2,
            h3,
            h4,
            h5 {
                font-size: 2rem;
            }
        }
    }

);