.page-header {
    top: 0;
    left: 0;
    right: 0;
    background-color: @white;
    z-index: 100;
    transition: height @transition, background-color @transition-short;
    color: @text-1;
    border-bottom: 1px solid @border-light;
    margin-bottom: 0;

    &.fixed {
        position: fixed;
    }

    .menu-open &,
    .account-open & {
        overflow-y: auto;
        overflow-x: hidden;
        height: 100vh;
        transition: none;
        position: sticky;

        .minW(@desktop-min, {
                border: 0 none;
                overflow: hidden;
            }

        );
    }

    .minW(@desktop-min, {

            .menu-open &,
            .account-open & {
                background-color: transparent;
            }
        }

    );

    ol,
    ul {
        list-style: none;
        padding: 0;
    }

    a:not([class^='button']) {
        color: @navy;
    }

    .skip-link {
        &:not(.skip-cart) {
            .minW(@desktop-min, {
                    color: @navy;
                }

            );
        }

        &.skip-account {

            .cms-home.menu-scrolled &,
            .cms-home.account-open & {
                color: @navy;
            }
        }
    }

    .container {
        height: @menu-height;
        position: relative;

        .menu-open &,
        .account-open & {
            top: 0;
            position: relative;
        }
    }

    .mobile-call {
        height: @mobile-call-height;
    }

    .logo {
        height: 26px;
        width: @logo-w;
        display: block;
        transition: height @transition, width @transition;
        .align-v();
        z-index: 10;
        margin: 0;

        .maxW(@mobile-md, {
                width: 130px;
                height: 20px;
            }

        );

        .maxW(360px, {
                width: @menu-height - 12px;
                height: @menu-height - 12px;
                overflow: hidden;

                img {
                    height: 100%;
                    width: auto;
                    max-width: none;
                }
            }

        );

        .maxW(@tablet-max, {
                .menu-open & {
                    display: none;
                }
            }

        );

        .minW(@desktop-min, {
                .align-v();
                left: @padding-tablet;

                .menu-open &, .account-open & {
                    .link-hidden;
                }
            }

        );

        .minW(@desktop-md, {
                width: @logo-w-md;
                height: 28px;
            }

        );

        .minW(@desktop-lg, {
                width: @logo-w-lg;
                height: 40px;
                left: @padding-desktop;
            }

        );
    }
}

.skip-link {
    .icon {
        stroke: @text-1;

        .minW(@desktop-min, {
                stroke: @navy;
            }

        );
    }

    &:hover {
        .icon {
            stroke: @navy;
        }
    }
}

.skip-links {
    position: relative;
    min-height: @menu-height;
    width: 100%;
    font-size: 1.4rem;

    .label {
        white-space: nowrap;
    }

    .minW(@desktop-min, {
            display: flex;
            align-items: center;
            margin-left: 145px;
        }

    );

    .minW(@desktop-md, {
            margin-left: 160px;
        }

    );

    .minW(@desktop-lg, {
            margin-left: 200px;
        }

    );
}

.skip-nav {
    .align-v();
    right: 0;
    margin: 0;
    z-index: 10;

    .menu-open & {
        left: 0;
        right: auto;
    }

    .icon {
        display: block;
        z-index: 101;
        height: 18px;
        width: 30px;

        span {
            display: block;
            height: 0;
            width: 100%;
            border-bottom: 2px solid @navy;
            transform-origin: 50% 50%;
            transition: all @transition;
            margin-bottom: 6px;

            &:first-child {
                .menu-open & {
                    transform: translateY(8px) rotate(45deg);
                }
            }

            &:nth-child(2) {
                .menu-open & {
                    opacity: 0;
                }
            }

            &:last-child {
                .menu-open & {
                    transform: translateY(-8px) rotate(-45deg);
                }
            }

            .menu-open & {
                border-color: @white;
            }
        }

        .minicart-open & {
            display: none;
        }
    }

    .label {
        .screen-readers();
    }
}

.skip-contact,
.skip-products {
    display: none;

    .icon {
        margin-right: @padding-mobile;
        transition: stroke .2s linear;
    }

    .minW(@desktop-min, {
            display: flex;
            align-items: center;
            margin-right: 2vw;
        }

    );
}

.skip-contact,
.skip-products {
    &:hover {
        .icon {
            stroke: @red;
        }
    }
}

.skip-search {
    .align-v();
    height: 100%;
    background: @navy;
    width: 5rem;
    right: 4rem;
    display: flex;
    align-items: center;
    justify-content: center;

    .icon {
        stroke: @white;
    }

    .minicart-open &,
    .menu-open & {
        display: none;
    }
}

.maxW(@tablet-max, {
        .minicart-open {
            .logo {
                display: none;
            }
        }

        .page-header-container {
            .menu-open & {
                background-color: @navy;
            }
        }

        .menu-open.mobile-search-open {
            .header-nav, .skip-account, .skip-search {
                display: none;
            }

            .minisearch {
                padding-bottom: 5rem;
            }
        }
    }

);

.minW(@desktop-min, {
        .page-header {
            .container {
                display: flex;
                align-items: center;
                justify-content: space-between;
            }
        }

        .skip-nav .icon {
            display: none;
        }

    }

);

@keyframes menu-transition {
    0% {
        transform: translateY(-@menu-height-lg);
    }

    100% {
        transform: translateY(0);
    }
}

.mobile-call {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: @gray;

    .minicart-open & {
        height: 0;
        overflow: hidden;
    }

    .maxW(@ipad-v - 1, {
            display: flex !important;
        }

    );

    .icon-phone {
        .icon;
        height: 18px;
        width: 22px;
        stroke: @navy;
        margin-right: @padding-mini;
    }

    .contact-page-link {
        font-weight: 700;
        color: @text-1;
    }
}

.phone-link {
    display: flex;
    align-items: center;
}