.cms-no-route {
    .block-subscribe {
        display: none;
    }

    .page-footer {
        background: none;
    }

    .column.main {
        .container;
        line-height: 1.5;
        text-align: center;
        font-size: 2.2rem;

        .std {
            color: @text-1;
            &:before {
                content: '';
                background-image: url('../images/pages/404/404.svg');
                background-repeat: no-repeat;
                background-position: center;
                height: 196px;
                width: 195px;
                margin: 2rem auto 0 auto;
                display: block;
            }
            .button {
                .button-large();
            }
        }

        b,
        strong {
            font-weight: 700;
        }

        .minW(@tablet-md, {
                font-size: 3rem;
            }

        );

        .minW(@desktop-min, {
                font-size: 4rem;
            }

        );
    }
}