.top-klub-logo {
    width: 280px;
    height: auto;
    flex-shrink: 0;

    .maxW(@tablet-max, {
            margin: auto;
        }

    );
}

.top-klub-offer {
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    .maxW(@tablet-max, {
            flex: auto;
        }

    );

    .offer-details {
        color: @navy;
        font-weight: 700;
        margin-right: @padding-tablet;
        line-height: 2;
        white-space: nowrap;
        font-size: 2rem;

        .maxW(@tablet-max, {
                text-align: center;
                width: 100%;
                margin: @padding-mobile auto;
                font-size: 1.6rem;

                .offer-heading {
                    font-size: 140%;
                }
            }

        );
    }

    .offer-counter {
        display: flex;
        gap: @padding-mobile;

        .counter-item {
            width: 96px;
            height: 110px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            position: relative;
            color: @navy;
            background: linear-gradient(0deg, rgba(185, 185, 185, .6) 50%, @text-4 50%);
        }

        .value {
            font-size: 4rem;
            font-weight: 700;
        }

        .label {
            position: absolute;
            bottom: 5px;
            font-size: 1.4rem;
        }

        .maxW(@tablet-max, {
                margin: auto;
                gap: 1rem;

                .counter-item {
                    width: 65px;
                    gap: .4rem;
                    height: 80px;
                }

                .value {
                    font-size: 3rem;
                }
            }

        );
    }
}

.top-klub-offer-summary {
    display: inline-flex;
    padding: @padding-mobile;
    border: 1px solid @border-light;
    line-height: 2;
    white-space: nowrap;
    background-color: @main-bg;

    .values {
        color: @red;
        padding-left: @padding-mobile;
    }

    .minW(@desktop-min, {
            padding: @padding-tablet;
        }

    );

    .maxW(@tablet-max, {
            font-size: 1.2rem;
            white-space: normal;
            margin: auto;
        }

    );
}

.top-klub-benefit {
    width: 260px;
    height: 260px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 0 0 auto;

    &:before {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        background-image: url('../images/top-klub/top-klub-bg.png');
        background-size: auto;
        background-repeat: no-repeat;
        background-position: center;
        filter: grayscale(0%);
        transition: all @transition;
    }

    &:hover {
        cursor: pointer;

        &:before {
            filter: grayscale(100%);
        }
    }

    .icon {
        max-height: 40px;
        max-width: 50px;

        &.icon-delivery {
            max-width: 66px;
        }

        &.icon-timer {
            max-height: 48px;
        }

        &.icon-inspection {
            max-height: 44px;
        }
    }

    .benefit-content {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        width: calc(100% - 40px);
        height: calc(100% - 40px);
        background-color: @white;
        border-radius: 50%;
        z-index: 1;
    }

    .benefit-description {
        font-weight: 700;
        color: @navy;
        margin-top: 1rem;
        text-align: center;
        padding: 0 2rem;
        line-height: 1.2;
    }

    .benefit-read-more {
        display: flex;
        align-items: center;
        flex-direction: column;

        .label {
            font-size: 80%;
            text-decoration: underline;
        }
    }
}

.plus-icon,
.minus-icon {
    color: @red;
    font-size: 40px;
    line-height: 1;
}