.button-border(@color, @colorBg, @colorActive: @color) {
    .button-black-base();
    border: 2px solid @color;
    color: @color;
    background-color: @colorBg;
    border-width: 1px;
    .transition(border-color .2s, color .2s; );

    .icon {
        stroke: @color;
    }

    &:active,
    &:hover,
    &:focus {
        border-color: @colorActive;
        color: @colorActive;
        border-width: 1px;

        .icon {
            stroke: @colorActive;
        }
    }
}

.button-solid(@color, @color-text) {
    border-color: @color;
    background-color: @color;
    color: @color-text;
    .transition(border-color .2s, color .2s, background-color .2s; );

    .icon {
        stroke: @color-text;
    }

    &:active,
    &:hover,
    &:focus {
        border-color: @color;
        background-color: transparent;
        color: @color;

        .icon {
            stroke: @color;
        }

        &:visited {
            color: @color;
        }
    }

    &:visited {
        background-color: @color;
        border-color: @color;
        color: @color-text;
    }
}

.button-base() {
    .form-reset();
    border: 2px solid @navy;
    color: @navy;
    line-height: 1;
    text-decoration: none;
    cursor: pointer;
    .transition(border-color .2s, color .2s; );
    -webkit-tap-highlight-color: transparent;

    &:active,
    &:hover,
    &:focus {
        border-color: @navy;
        border-width: 2px;
        color: @navy;
    }
}

.button {
    .button-base();
    padding: .8rem 2.6rem;
    border-radius: 40px;
    white-space: nowrap;
    display: inline-block;
    font-family: @font-1;
    font-size: 1.2rem;
    font-weight: 700;

    &:hover,
    &:active,
    &:focus {
        text-decoration: none;
    }

    .minW(@desktop-lg, {
            padding: 1.2rem @padding-mobile;
            font-size: 1.4rem;
        }

    );
}

.button-small {
    padding: .6rem 2rem;
    font-size: 1.2rem;

    .minW(@desktop-lg, {
            padding: .6rem 2rem;
            font-size: 1.2rem;
        }

    );
}

.button-large() {
    padding: 1.5rem 4rem;
    font-size: 1.6rem;
}

.button-yellow {
    .button();
    .button-solid(@yellow, @text-1);

    &:focus {
        color: @yellow;
    }
}

.button-orange {
    .button();
    .button-solid(@orange, @text-1);

    &:focus {
        color: @orange;
    }
}

.button-red {
    .button();
    .button-solid(@red, @white);
}

.button-nav-products {
    .button-black-base();
    .button-solid(@red, @white);

    &:active,
    &:hover,
    &:focus {
        background-color: @red;
        color: @white;

        .icon {
            stroke: @white;
        }

        &:visited {
            color: @white;
        }
    }

    &:visited {
        background-color: @red;
        color: @white;
    }
}

.button-dark {
    .button();
    .button-solid(@navy, @white);
}

.button-white {
    .button();
    .button-solid(@white, @text-1);
}

.button-black {
    .button-black-base();
    .button-solid(@navy, @white);
    border-width: 1px;

    &:active,
    &:hover,
    &:focus {
        border-width: 1px;
    }
}

.button-navy-outline {
    .button-outline(@navy, @navy);
}

.button-red-outline {
    .button-outline(@red, @black);
}

.button-green {
    .button();
    .button-solid(@green, @white);
}

.button-light {
    .button();
    .button-outline(@border-light, @border-light, @navy, @navy);
}

.button-outline(@colorBorder, @colorText, @activeBorder: @colorBorder, @activeText: @colorText) {
    .button-black-base();
    border-width: 1px;
    border-color: @colorBorder;
    background-color: @white;
    color: @colorText;
    .transition(border-color .2s, color .2s, background-color .2s; );

    .icon {
        stroke: @colorText;
    }

    &:active,
    &:hover,
    &:focus {
        border-color: @activeBorder;
        background-color: @white;
        color: @activeText;
        border-width: 1px;

        .icon {
            stroke: @colorText;
        }

        &:visited {
            color: @colorText;
        }
    }

    &:visited {
        background-color: @white;
        border-color: @colorBorder;
        color: @colorText;
    }
}

.button-black-base() {
    .button;
    border-color: @black;
    color: @black;
    font-family: @font-2;
    font-size: 1.6rem;
    font-weight: 400;
    height: 42px;
    display: inline-flex;
    align-items: center;
    padding-left: @padding-mobile;
    padding-right: @padding-mobile;

    .icon+label {
        margin-left: @padding-mini;
    }

    .label+.icon {
        margin-left: @padding-mini;
    }
}
