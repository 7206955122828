header {
  .header-top-message {
    background:  #002c5c;
    text-align: center;
    .minW(@desktop-lg, {
      background: #e6e5e4;
    });
    p {
      padding: 10px 15px;
      color: #fff;
      .minW(@desktop-lg, {
        font-size: 1.1em;
        color: #000;
      });
      line-height: 1.2em;
    }
  }
}

.minicart-open {
  header {
    .header-top-message {
      width: 330px;
      float: right;
      .minW(@desktop-lg, {
        width: @minicart-width-lg;
      });
    }
    .container {
      clear: both;
    }
  }
}