.product-siblings {
  margin: 15px 0;
  &_label {
    display: block;
    margin-bottom: 10px;
    font-weight: bold;
  }
  &_attributes {
    display: flex;
    gap: 5px;
    flex-wrap: wrap;
  }
  &_item {
    font-weight: bold;
    display: inline-block;
    white-space: nowrap;
    border: 1px solid #999999;
    &.current {
      border: 1px solid #000;
    }
    a {
      font-weight: normal;
      text-decoration: none;
    }
    &.current, a {
      display: inline-block;
      font-size: 12px;
      padding: 4px 8px;
    }
    &:not(.current) {
      &:hover {
        border: 1px solid #ffffff;
        outline: 1px solid #000;
      }
    }
  }
}