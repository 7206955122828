@delivery-installation-message-width: 600px;

.delivery-installation-message {
    background-color: @white;
    display: flex;
    box-shadow: @box-shadow-content;
    margin-bottom: @padding-mobile;
    width: @delivery-installation-message-width;
    max-width: 100%;
    text-transform: none;

    strong {
        color: @red;
    }

    .message {
        flex: 1;
        font-size: 1.4rem;
    }

    .message-content {
        padding: @padding-mobile;
        padding-top: 0;
    }

    .message-title {
        display: flex;
        align-items: center;
        line-height: 1.1;
        padding: @padding-mini @padding-mobile;
    }

    .message-icon {
        min-width: 60px;
        display: flex;
        align-items: center;
        margin-right: 1rem;

        .icon {
            max-width: 60px;
            max-height: 47px;
        }
    }

    .minW(@desktop-min, {
            min-width: 320px;
            margin-left: @padding-mobile;
            margin-top: -62px;
        }

    );

    .maxW(@mobile-md, {
            flex-direction: column;
        }

    );
}