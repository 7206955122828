.box-orders {
	.box-recent;

	.box-head {
		.limiter {
			width: auto;
			display: flex;
			flex-wrap: wrap;
			justify-content: flex-start;
			align-items: center;
			margin: 0 @padding-mobile;

			label {
				position: static;
				width: auto;
				height: auto;
				overflow: visible;
				color: @text-1;
				font-weight: 700;
				margin-right: @padding-mini;
			}

			.nice-select {
				margin-top: 0;
			}
		
		}
	}
}

.sales-order-history {
	.cart-import {
		border: 1px solid #d3d3d3;
	}
}

.minW(@ipad-v,  {
	.box-orders {
		.box-head {
			position: relative;
	
			.limiter {
				background-color: @white;
				box-shadow: 10px 0 @white, -10px 0 @white;
				position: absolute;
				right: 1rem;
				top: -2rem;
			}

			.nice-select {
				width: auto;
				margin-bottom: 0;
			}
		}
	}
});

.minW(@desktop-min, {
	.box-orders  {
		.box-head {
			h2 {
				margin-left: @padding-tablet;
			}

			.limiter {
				margin-right: @padding-tablet;
			}
		}
	}
});