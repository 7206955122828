.block-videos {
    .videos {
        background-color: @black;
        position: relative;
        padding-top: 32.25%;
        overflow: hidden;
        cursor: pointer;

        .maxW(@ipad-v - 1, {
            padding-top: 56.25%;
        });

        &:not(:last-of-type) {
            margin-bottom: @padding-tablet;
        }

        iframe {
            height: 100%;
            width: 100%;
            top: 0;
            left: 0;
        }

        img,
        .play-button {
            cursor: pointer;
        }

        img {
            width: 100%;
            top: -16.84%;
            left: 0;
            opacity: 0.7;
        }

        .play-button {
            width: 90px;
            height: 60px;
            background-color: #333;
            box-shadow: 0 0 30px rgba( 0,0,0,0.6 );
            z-index: 1;
            opacity: 0.8;
            border-radius: 6px;

            &:before {
                content: '';
                border-style: solid;
                border-width: 15px 0 15px 26.0px;
                border-color: transparent transparent transparent #fff;
            }
        }

        img,
        iframe,
        .play-button,
        .play-button:before {
            position: absolute;
        }

        .play-button,
            .play-button:before {
            top: 50%;
            left: 50%;
            transform: translate3d( -50%, -50%, 0 );
        }
    }
}
