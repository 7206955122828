.account-top-klub {
    max-width: 1200px;

    .row {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        flex-wrap: wrap;
        margin-bottom: 2rem;
        gap: 2rem;

        &.row-offer {
            align-items: center;
        }
        &.not-loyalty {
          justify-content: flex-start;
        }
    }

    .minW(@desktop-min, {
            .top-klub-offer-summary {
                margin-top: 3rem;
            }
        }

    );

    .minW(1445px, {
            .top-klub-offer-summary {
                margin-top: 5rem;
            }
        }

    );
}

.top-klub-benefit-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding-bottom: 3rem;
    gap: 4rem;

    .benefit-conditions {
        width: 100%;
    }

    .minW(@desktop-min, {
            &.active {
                gap: 1.5rem;
            }
        }

    );

    .maxW(@tablet-max, {
            flex-direction: column;
            align-items: center;

            .top-klub-benefit-item {
                margin: @padding-tablet auto;
            }
        }

    );
}

.top-klub-benefit-item {
    &.active {
        display: flex;
        width: 100%;
    }

    .minW(@desktop-min, {
            &.active {
                order: 99;
                border-top: 1px solid @border-light;
                padding-top: @padding-tablet;
                margin-top: @padding-tablet;

                .top-klub-benefit {
                    margin: 0 @padding-mobile 0;
                }
            }

        }

    );

    .maxW(@tablet-max, {

            &.active {
                flex-direction: column;
                align-items: center;
                border-bottom: 1px solid @border-light;
                padding-bottom: @padding-tablet;
            }

        }

    );

    .maxW(@mobile-max, {
            margin: @padding-mobile auto;
        }

    );
}

.benefit-conditions {
    padding: 0 @padding-mobile;

    .plus-icon,
    .benefit-conditions-title {
        margin-bottom: @padding-mobile;
    }
}

.block-top-klub-benefits {
    .maxW(@mobile-max, {
            .block-content {
                padding: 0;
            }
        }

    );
}

.top-klub-offer-products {
    width: 700px;
    max-width: 100%;

    .products-title {
        padding-top: @padding-mobile;
        font-size: 2.2rem;

        .maxW(@tablet-max, {
                text-align: center;
            }

        );
    }

    .products-grid {
        padding-top: 0;
        margin: 0 @padding-mobile;
    }

    .products {
        margin-top: 0;
    }

    .product-items {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        padding-top: 0;
    }

    .maxW(@tablet-max, {
            flex: auto;

            .products-grid {
                padding-top: @padding-mobile;
            }
        }

    );

    .maxW(@ipad-v, {
            .products-grid {
                .product-items .product-item {
                    width: auto;
                    max-width: 325px;
                }
            }
        }

    );

    .minW(@tablet-max, {
            margin-right: -@padding-mobile;
            margin-left: -@padding-mobile;
            display: flex;
            flex-direction: column;

            .product-item {
                margin: @padding-mobile;
            }

            .products-title {
                width: calc(~"(320px * 2) + @{padding-tablet}");
                align-self: center;
                font-size: 2rem;
            }
        }

    );

    .minW(1445px, {
            .product-items {
                justify-content: flex-end;
            }

            .products-title {
                text-align: left;
                padding-top: 0;
            }
        }

    );
}