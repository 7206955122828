.opineo-side-slider-widget {
    z-index: 50 !important;
}

.field-recaptcha {
    display: none;
}

.lac-notification-sticky {
    z-index: 100;
}
.t-right {
    text-align: right;
}
.t-left {
    text-align: left;
}
.t-center {
    text-align: center;
}