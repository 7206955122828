.account-top-klub {
  .products-grid {
    .product-items {
      .product-item {
        width: calc(50% - 1.5rem * 2);
      }
    }
  }
}

.top-klub-offer-products {
  .product-items {
    .not-in-program {
      position: relative;
      &:before {
        content: '';
        display: block;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(236, 236, 236, 0.8);
        z-index: 2;
        cursor: pointer;
      }
    }
  }
}