.messages-wrapper {
    .align();
    .list-unstyled;
    position: fixed;
    width: 600px;
    max-width: 100%;
    z-index: 102;
    font-weight: 700;
}

.message-inner {
    border-width: 2px;
    border-style: solid;
    border-radius: @radius;
    background-color: @white;
    box-shadow: @box-shadow-message;
    cursor: pointer;
    margin: @padding-mobile;
}

.messages {
    .messages-wrapper();

    .notice-msg {
        padding: @padding-mobile;
    }

    .message {
        padding: @padding-tablet @padding-tablet @padding-tablet @padding-tablet + @padding-mobile;
    }

    .message,
    .notice-msg {
        .message-inner();

        &:before,
        ul li:before {
            content: none !important;
        }

        >*:first-child:before {
            top: 50%;
            left: @padding-mini;
        }
    }

    .message-success {
        border-color: @success;
        color: @success;

        >*:first-child:before {
            color: @success;
        }
    }

    .message-error {
        border-color: @yellow-dark;
        color: @yellow-dark;

        >*:first-child:before {
            color: @yellow-dark;
        }
    }
}