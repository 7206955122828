@import (inline) '../vendors/tippy/tippy.css';
@import (inline) '../vendors/tippy/animations/shift-away.css';

.tippy-box[data-theme=tiptopol] {
    background-color: @yellow-dark;

    .tippy-content {
        color: @text-1;
        font-size: 1.2rem;
        line-height: 1.2;
        text-align: center;
        padding: @padding-mini * 2;
    }

    &[data-placement^='top'] .tippy-arrow::before {
        border-top-color: @yellow-dark;
    }
    &[data-placement^='bottom'] .tippy-arrow::before {
        border-bottom-color: @yellow-dark;
    }
    &[data-placement^='left'] .tippy-arrow::before {
        border-left-color: @yellow-dark;
    }
    &[data-placement^='right'] .tippy-arrow::before {
        border-right-color: @yellow-dark;
    }
}

.tooltip {
	font-family: @font-1;
	color: @text-3;
	border: 2px solid @text-3;
	width: 20px;
	height: 20px;
	border-radius: 50%;
	display: inline-block;
	text-align: center;
	vertical-align: middle;
	line-height: 16px;
	margin-right: 1rem;
	font-size: 1.3rem !important;
	font-weight: 700 !important;
	cursor: pointer;
}
