.cms-kontakt {
    .std {
        .input-message {
            align-items: baseline;

            textarea {
                min-height: 100px;
            }
        }
    }
}

.cms-page-view.kontakt-index-index {
    .std {
        background-color: transparent;
        padding: 0;
    }
}

.contact-list {
    padding-left: 0;

    li {
        margin-bottom: @padding-desktop;
    }
}

.contact-item {
    margin-bottom: @padding-tablet;
}

.contact-item-footer {
    border-top: 1px solid @border-light;
    padding-top: @padding-tablet;
    width: 100%;
}

.widget-social {
    max-width: 320px;

    .block-title {
        font-size: 2rem;
        display: flex;
        justify-content: space-between;
        align-items: self-end;
        margin-bottom: 2rem;

        a {
            display: inline-flex;
        }
    }

    .block-content {
        background: transparent;
        padding: 0;

        blockquote {
            margin: 0;
        }
    }

    #fb-root {
        height: 0;
    }
}

.map {
    position: relative;

    .icon {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        max-height: 100%;
        max-width: 100%;
        height: 100%;
        width: 100%;
    }
}

.contact-form {
    .clearfix;

    .input-box {
        label {
            white-space: nowrap;
            line-height: 1;
            color: @text-2;
        }
    }

    .input-message {
        .input-text {
            min-height: 120px;
        }
    }

    .button {
        margin-top: 1rem;
    }

    .parsley-error .validation-advice {
        visibility: hidden;
    }

    .validation-advice {
        margin-bottom: 0;
    }

    .contact-form-info {
        color: @text-2;
        margin-top: @padding-tablet;
        margin-bottom: @padding-mobile;
    }

    .contact-form-footer {
        margin-top: @padding-tablet;
    }
}

.map-container {
    position: relative;
    height: 100%;
}

.headquarters-map {
    height: 300px;
    margin-bottom: @padding-tablet;
}

.select-distribution {
    float: none;
    margin-bottom: @padding-tablet;
}


.distributors-map {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;

    .icon {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        max-height: 100%;
        max-width: 100%;
        height: 100%;
        width: 100%;
    }
}

.distribution-list {
    li {
        display: none;
    }

    .active {
        display: block;
    }
}

.marker-list {
    font-weight: 700;

    li {
        position: absolute;
        cursor: pointer;
        transition: color @transition-short;

        &:after {
            content: '';
            display: block;
            height: 14px;
            width: 14px;
            border: 4px solid @gray;
            border-radius: 50%;
            .align-h();
            top: -12px;
            transition: border-color @transition-short, background-color @transition-short;
        }

        &:hover {
            color: @text-1;

            &:after {
                border-color: @text-1;
            }
        }

        &:nth-child(1) {
            // Chełm
            top: 65%;
            left: 86%;
        }

        &:nth-child(2) {
            // Gdańsk
            top: 15%;
            left: 37%;
        }

        &:nth-child(3) {
            // Kraków
            top: 80%;
            left: 52%;
        }

        &:nth-child(4) {
            // Lubin
            top: 58%;
            left: 15%;
        }

        &:nth-child(5) {
            // Olsztyn
            top: 26%;
            left: 57%;
        }

        &:nth-child(6) {
            // Piotrków Trybunalski
            top: 58%;
            left: 35%;
        }

        &:nth-child(7) {
            // Szczecin
            top: 32%;
            left: 2%;
        }

        &:nth-child(8) {
            // Toruń
            top: 35%;
            left: 36%;
        }

        &:nth-child(9) {
            // Warszawa
            top: 48%;
            left: 60%;
        }
    }

    .active {
        color: @text-1;

        &:after {
            border-color: @text-1;
            background-color: @text-1;
        }
    }
}

.widget {

    h2,
    h3 {
        margin-bottom: @padding-mobile + @padding-mini;
    }
}

.contact-block {

    .block-content {
        padding-top: @padding-mobile;
    }

    .contact-form {
        .input-box {
            flex-wrap: wrap;

            .form-label {
                width: 100%;
                flex: auto;
                font-weight: 500;
                margin-bottom: @padding-mobile;
            }

            .input-text {
                background-color: @main-bg;
            }
        }

        .checkbox-styled {
            padding-bottom: @padding-mobile;
        }
    }
}

.minW(@desktop-min, {
        .contact-block {
            .contact-form {
                display: flex;
                flex-wrap: wrap;
                gap: @padding-tablet;
            }

            .contact-form-col {
                flex: 2;

                &:first-of-type {
                    flex: 1;
                }

                .actions {
                    display: flex;
                    justify-content: flex-end;
                }

            }
        }
    }

);

.minW(@desktop-sm, {
        .contact-widgets {
            display: flex;
            width: 100%;
            justify-content: space-between;
            gap: 3rem;
        }

        .widget-social {
            flex: 1;
        }

        .widget-map {
            flex: 2;
        }
    }

);

.minW(@desktop-lg, {
        .contact-item {
            margin-bottom: @padding-desktop;
            flex: 1;
        }
    }

);

.maxW(@mobile-max, {

        .widget {
            h2, h3 {
                text-align: center;
            }
        }

        .contact-item {
            text-align: center;
        }

        .contact-form {
            .button {
                .button-large;
                width: 100%;
            }
        }

        .headquarters-map {
            margin-left: -@padding-mobile;
            margin-right: -@padding-mobile;

        }

    }

);

.minMax(@mobile-max + 1px, @tablet-md, {
        .headquarters-map {
            margin-left: -@padding-tablet;
            margin-right: -@padding-tablet;
        }
    }

);

.minMax(@tablet-max + 1px, @desktop-sm - 1px, {
        .contact-items {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
        }

        .contact-item {
            width: calc(~"50% - @{padding-mobile}");
        }

    }

);

.minW(@desktop-md, {
        .contact-items {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            gap: @padding-mobile;
        }

        .contact-item {
            width: calc(~"50% - @{padding-mobile}");
        }
    }

);

.regions-map {
    position: relative;
    width: 100%;
    max-width: 400px;
    margin: auto;

    .region {
        transition: fill @transition-short;
        cursor: pointer;
        position: relative;
        -webkit-tap-highlight-color: transparent;
    }

    #obrys {
        stroke: @text-1;
    }

    .active,
    .region:hover {
        fill: #F4F0F0;
    }
}

.region-labels {
    pointer-events: none;
    color: @text-1;
    font-weight: 700;
    font-size: 3.2vw;
    line-height: 1.2;

    li {
        position: absolute;
        display: none;
        &:nth-child(1) {
            // zachód
            top: 34%;
            left: 15%;
            display: list-item;
        }

        &:nth-child(2) {
            // wschód
            top: 50%;
            left: 50%;
            display: list-item;
        }
    }
}

.region-list {
    margin-bottom: @padding-tablet;

    h2 {
        cursor: pointer;
    }

    li {
        display: none;
        margin-bottom: 30px;

        &.active {
            display: block;
        }
    }

    .cities {
        margin-top: 20px;

        p {
            line-height: 1.2em;
            margin-bottom: 5px;
        }

        .active {
            font-weight: bold;
        }
    }

    .city-description {
        margin-top: 3em;
    }
}

.cms-siec-dystrybucji {
    h1 {
        width: 100%;
    }

    #distributor-map-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    .regions-map {
        display: none;
    }
}

.minW(@distributors-map-width + 60px, {
        .region-labels {
            font-size: 1.6rem;
        }

    }

);

.maxW(@ipad-v, {
        .regions-map {
            order: 1;
        }

        .region-list {
            order: 2;

            .city-description {
                margin-top: 2em;
            }
        }

        .cms-siec-dystrybucji {
            .std {
                flex-direction: column;
            }
        }
    }

);

.minW(@ipad-v, {
        .region-list {
            width: calc(~"100% - 400px");
            float: left;
            clear: left;

        }

        .regions-map {
            width: 400px;
            float: right;
            clear: none;
        }
    }

);