
.related {
	margin: 0 0 6rem;

	.block-title {
		margin-bottom: 0;
	}

    .product-items {
        padding-top: @padding-mini;
    }
}

.minW(@mobile-max, {
	.related {
		.block-title {
			padding-left: @padding-tablet;
		}
	}
});

.minW(@desktop-md, {
	.page-layout-3columns {
		.columns .related .product-items {
			.item, .product-item {
				width: calc(~"33.33% - @{padding-mobile} * 2");
			}
		}
	}
});

.minMax(@desktop-min, @desktop-sm -1px, {
    .page-layout-3columns {
		.columns .related .product-items {
			margin: @padding-mobile;
			width: calc(~"33.33% - @{padding-mobile} * 2");
		}
	}
});

.minMax(@desktop-sm, @desktop-md + 100px, {
    .page-layout-3columns {
		.columns .related .product-items {
			.item, .product-item {
				width: calc(~"50% - @{padding-mobile} * 2");
			}
		}
	}
});

.minW(@desktop-md + 100px, {
	.page-layout-3columns {
		.related .product-items {
			.item, .product-item {
				width: calc(~"33.33% - @{padding-mobile} * 2");
			}
		}
	}
});
