.skip-account {
    display: none;
    .align-v();
    right: 0;
    z-index: 10;
    max-width: 135px;

    .label {
        line-height: 1;
        white-space: nowrap;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        text-align: right;

        .minW(@desktop-min, {
                max-height: 32px;
                max-width: 80px;
            }

        );

        .minW(@desktop-lg, {
                max-width: 101px;
                max-height: 40px;
            }

        );
    }


    .icon {
        margin-left: @padding-mini;
        max-height: 22px;

        &:first-of-type {
            order: 3;
        }
    }

    .icon-arrow-small {
        transform: rotate(-90deg);
    }

    &:hover {
        .icon-arrow-small {
            stroke: @red;
        }
    }

    .account-open & {
        .icon {
            stroke: @navy;
        }

        .icon-arrow-small {
            transform: rotate(90deg);
        }
    }

    .menu-open & {
        display: flex;
        align-items: center;
        height: @menu-height;
    }

    .maxW(@tablet-max, {
            .nav-button-link();
            display: flex;
            justify-content: center;
            align-items: center;
            width: @menu-height - 12px;
            background-color: @white;
            border: 1px solid @navy;
            border-radius: 50%;
            margin-right: 153px;

            .menu-open &,
            .minicart-open & {
                display: none !important;
            }

            .account-open & {
                background-color: @navy;

                .icon-user {
                    stroke: @white;
                }
            }

            .icon {
                margin-left: 0;
            }

            .icon-user {
                stroke: @navy;
            }

            .label {
                display: none;
            }
        }

    );


    .minW(@desktop-min, {

            .minicart-open & {
                display: none;
            }

            .menu-open & {
                .link-hidden;
            }
        }

    );

}
