.firecheckout .checkout-container .opc-progress-bar {
    margin: auto;
    padding: 4rem 4rem 4rem 0;
    width: 100%;
    display: flex;
    justify-content: space-between;


    .minW(@mobile-max, {
            width: calc(63% - 27px);
        }

    );

    .opc-progress-bar-item {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        flex: 1;

        &:after {
            content: none;
        }

        span {
            position: relative;
            width: 100%;
            display: flex;
            justify-content: center;
            text-align: center;
            line-height: 1;

            &:before,
            &:after {
                content: '';
                display: block;
            }

            &:before {
                position: absolute;
                border-bottom: 1px solid @text-3;
                border-radius: 0;
                height: 0;
                width: 100%;
                top: 20px;
                left: 0;
                right: 0;
                z-index: 2;
                margin: @padding-mobile auto 0;
            }

            &:after {
                border: 1px solid @text-3;
                width: @padding-mobile;
                height: @padding-mobile;
                border-radius: 30px;
                background-color: @white;
                margin-top: 22px;
                margin-left: -7px;
                z-index: 3;
            }
        }

        &.active,
        &._active,
        &.active~.opc-progress-bar-item,
        &._active~.opc-progress-bar-item {
            span {

                &:after {
                    background-color: @red;
                    border-color: @red;
                }

                &:before {
                    border-color: @red;
                }
            }
        }

        &.active~.opc-progress-bar-item,
        &._active~.opc-progress-bar-item {
            span {
                color: @text-1;
            }
        }

        &.active,
        &._active {
            span {
                color: @red;
                font-weight: 700;
            }
        }

        &.completed,
        &._complete {
            span {
                color: @text-3;

                &:after {
                    border-color: @text-3;
                }

                &::before {
                    border-color: @text-3;
                }
            }
        }

        &:first-child {
            display: none;

            +li {

                span:before {
                    left: 50%;
                    width: auto;
                }
            }
        }

        &:last-child span:before {
            right: 50%;
            width: auto;
        }

        &:first-child::before,
        &:last-child::after {
            content: none;
        }
    }


}