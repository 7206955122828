@font-face {
	font-family: 'Cinzel';
	font-style: normal;
	font-weight: 400;
	font-display: auto;
	src: url(../fonts/google/Cinzel-normal-400.woff) format('woff');
	unicode-range: U+0-10FFFF;
}

@font-face {
	font-family: 'Muli';
	font-style: normal;
	font-weight: 400;
	font-display: auto;
	src: url(../fonts/google/Muli-normal-400.woff) format('woff');
	unicode-range: U+0-10FFFF;
}

@font-face {
	font-family: 'Muli';
	font-style: normal;
	font-weight: 600;
	font-display: auto;
	src: url(../fonts/google/Muli-normal-600.woff) format('woff');
	unicode-range: U+0-10FFFF;
}

@font-face {
	font-family: 'Muli';
	font-style: normal;
	font-weight: 800;
	font-display: auto;
	src: url(../fonts/google/Muli-normal-800.woff) format('woff');
	unicode-range: U+0-10FFFF;
}

@font-face {
	font-family: 'Muli';
	font-style: normal;
	font-weight: 900;
	font-display: auto;
	src: url(../fonts/google/Muli-normal-900.woff) format('woff');
	unicode-range: U+0-10FFFF;
}

@font-face {
	font-family: 'Nunito Sans';
	font-style: normal;
	font-weight: 400;
	font-display: auto;
	src: url(../fonts/google/Nunito_Sans-normal-400.woff) format('woff');
	unicode-range: U+0-10FFFF;
}

@font-face {
	font-family: 'Nunito Sans';
	font-style: normal;
	font-weight: 700;
	font-display: auto;
	src: url(../fonts/google/Nunito_Sans-normal-700.woff) format('woff');
	unicode-range: U+0-10FFFF;
}

@font-face {
	font-family: 'Nunito Sans';
	font-style: normal;
	font-weight: 900;
	font-display: auto;
	src: url(../fonts/google/Nunito_Sans-normal-900.woff) format('woff');
	unicode-range: U+0-10FFFF;
}

@font-face {
	font-family: 'Oswald';
	font-style: normal;
	font-weight: 300;
	font-display: auto;
	src: url(../fonts/google/Oswald-normal-300.woff) format('woff');
	unicode-range: U+0-10FFFF;
}

@font-face {
	font-family: 'Oswald';
	font-style: normal;
	font-weight: 400;
	font-display: auto;
	src: url(../fonts/google/Oswald-normal-400.woff) format('woff');
	unicode-range: U+0-10FFFF;
}

@font-face {
	font-family: 'Oswald';
	font-style: normal;
	font-weight: 700;
	font-display: auto;
	src: url(../fonts/google/Oswald-normal-700.woff) format('woff');
	unicode-range: U+0-10FFFF;
}

