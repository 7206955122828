.slider {
    .owl-item {
        img {
            width: auto;
        }
    }

    .owl-dot {
        height: 1.2rem;
        width: 1.2rem;
        background-color: @yellow;
        border-radius: 50%;
        display: inline-block;
        margin: 0 .6rem;
        transition: background-color @transition-short;

        &.active,
        &:hover {
            background-color: @text-1;
        }
    }
}

.owl-prev,
.owl-next {
    -webkit-tap-highlight-color: transparent;
}

.slider-category {
    background-color: @main-bg;
    min-height: @slider-cat-h-lg;
    height: @slider-cat-h-lg;

    .minW(@desktop-sm, {
            display: flex;
            min-height: @slider-cat-h;
            height: @slider-cat-h;
            align-items: center;
            justify-content: space-between;
        }

    );

    .minW(@desktop-lg, {
            min-height: @slider-cat-h-lg;
            height: @slider-cat-h-lg;
        }

    );

    .owl-stage-outer {
        padding: 2rem 0 2rem;
    }

    .category-label {
        padding-top: 1rem;
        text-align: center;
        line-height: 1.2;
        font-size: 1.3rem;
        max-width: 100%;
        text-transform: uppercase;
    }

    .owl-item {
        padding: 0 10px;
    }

    .owl-next {
        transform: rotate(-90deg);
    }

    .owl-prev {
        transform: rotate(90deg);
    }

    .item,
    .product-item {
        display: flex;
        align-items: center;
        justify-content: space-around;
        flex-direction: column;
        height: 120px;
        width: 120px;
        max-width: 100%;
        margin: auto;
        padding: @padding-mini*2;
        color: @text-1;
        text-decoration: none;
        background-color: @white;
        border-radius: 7px;
        box-shadow: 0 7px 20px -7px @category-item-shadow;
        transition: box-shadow @transition;

        &:hover {
            box-shadow: 0 10px 25px -4px @category-item-shadow;
        }

        &.promocja,
        &.promocje {
            background-color: @red;

            .category-label {
                color: @white;
            }
        }

        img {
            display: block;
            max-height: 54px;
            max-width: 100%;
            height: auto;
            width: auto;
        }

        &.top-club img {
            max-height: 100%;
        }

        .minW(@desktop-sm, {
                max-width: 120px;
                flex: 1 0 auto;
                margin: auto 10px;
            }

        );

        .minW(@desktop-lg, {
                width: 160px;
                height: 160px;
                max-width: 160px;
                margin: 5px;

                img {
                    max-height: 80px;
                }

                &.top-club img {
                    max-height: 100%;
                }
            }

        );
    }

    .owl-nav {
        .container();
        display: flex;
        align-items: center;
        justify-content: space-between;

        .icon {
            stroke: @text-1;
            .transition(stroke .2s);
        }

        [class*=owl-] {
            background-color: transparent;

            &.disabled {
                pointer-events: none;
                opacity: .3;
            }
        }
    }

}

.slider-recommended,
.slider-recommended-3col {
    padding-left: 1rem;
    padding-right: 1rem;
    overflow: hidden;

    .owl-stage-outer {
        overflow: visible;
    }

    .item,
    .product-item {
        margin: 10px auto;
    }

    .minW(@desktop-min, {
            padding-left: 2rem;
            padding-right: 2rem;
            padding-bottom: 5.5rem;
        }

    );

    .minW(@desktop-lg, {
            .item, .product-item {
                width: 410px;
            }
        }

    );

    .minMax(@mobile-md, @desktop-min, {
            .item, .product-item {
                max-width: 300px;
            }
        }

    );
}

.slider-recommended-3col {
    .product-image-wrapper .product-image-photo {
        margin: auto;
    }
}

.slider-articles {

    .article-image {
        height: 0;
        padding-bottom: 70%;
        position: relative;

        img {
            backface-visibility: hidden;
            max-height: 100%;
            .align();
        }
    }

    .post-holder {
        min-width: 100%;
    }

    .minMax(@mobile-max, @desktop-min, {
            .item {
                max-width: 320px;
                margin: auto;
            }
        }

    );
}

.slider-user {

    flex-grow: 1;

    &.owl-loaded {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
    }

    .owl-dots {
        text-align: center;
    }

    .product-name,
    .product-item-name {
        font-size: 1.4rem;
        font-weight: 900;
        font-family: @font-1;
        margin-bottom: 0;

        a {
            max-height: 3.4rem;
            display: block;
            overflow: hidden;
        }
    }

    .product-image,
    .product-item-photo {
        height: 180px;
        margin-bottom: @padding-mobile;

        a {
            display: block;
            height: 100%;
        }

        img {
            display: block;
            margin: 0 auto;
        }
    }

    .price-box {
        display: flex;
        justify-content: space-between;

        .regular-price,
        .old-price,
        .special-price {
            flex: 1 1 auto;
        }

        .special-price {
            text-align: right;
        }

        .special-price {
            .price {
                color: @red;
                font-size: 1.8rem;
            }
        }

        &.regular-price-box {

            .special-price {
                display: none;
            }

            .regular-price {
                text-align: right;
                flex: 1 0 auto;
            }

            .price {
                font-size: 1.8rem;
            }
        }
    }

    .price-label {
        color: @text-3;
        font-size: 1.2rem;
        line-height: 1.1;
        display: block;
        margin-top: @padding-mini;
        margin-bottom: @padding-mini;
    }

    .price {
        font-weight: 900;
        line-height: 1;
        color: @text-1;
    }

    .actions {
        text-align: center;
    }

    .btn-cart {
        .button-red();
        // margin: @padding-mobile auto;
        margin-top: 1rem;
        margin-bottom: 1rem;
    }
}
