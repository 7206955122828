.review-form {
    textarea {
        border-width: 0 0 1px;
        border-color: @red;
        padding-left: @padding-mobile;
        padding-right: @padding-mobile;

        &:focus {
            box-shadow: none;
        }
    }

    input,
    textarea {
        font-family: @font-1;
    }

    .action.submit.primary {
        .button-dark;
    }

    .review-control-vote label:before {
        color: @yellow-dark;
    }

    .review-legend strong {
        margin-top: @padding-mobile;
    }

    .review-field-ratings .label {
        color: @text-1;
    }
}

.product-reviews-summary {
    .rating-summary .rating-result>span:before {
        color: @yellow-dark;
    }
}

.review-toolbar {
    border-color: @rgba-black;
}

[data-tab="reviews"] {
    .counter {
        &:before {
            content: '(';
        }

        &:after {
            content: ')';
        }
    }
}

.review-add {
    padding-top: 0;

    .block-content {

        &:before {
            display: none;
        }
    }
}

.column.main {

    .review-list,
    .review-add {

        .block-title,
        .block-content {
            padding-left: 1rem;
        }
    }

    .review-notice {
        padding-left: 1rem;
        margin: 5px 0 20px;
    }
}

.review-list {
    padding-top: 10px;

    .block-title {
        strong {
            font-weight: 700;
        }
    }

    .review-items {
        position: relative;

        @media screen and (min-width: 600px) {
            padding-left: 3rem;
        }
    }

    .review-item {
        text-indent: 0 !important;

        &:before {
            position: absolute;
            top: 0;
            left: 0;
        }

        .review-title {
            margin: 0 0 10px;
        }

        .review-content {
            font-style: italic;
            margin: 0 0 10px;
        }
    }

    .rating-summary .rating-result>span:before {
        color: @yellow-dark;
    }
}