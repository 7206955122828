@bgColor: #036637;
@bgSpecialColor: #87221a;

.product-list-calendar {

    .sorter,
    .toolbar,
    .toolbar-bottom,
    .toolbar-sorter {
        display: none;
    }

    .item,
    .product-item {
        visibility: hidden;

    }

    .items_ready {

        .item,
        .product-item {
            visibility: visible;
        }
    }

    .item,
    .product-item {
        border-color: @bgColor;

        &:before,
        &:after {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            line-height: 1;
            color: @white;
            font-weight: bold;
            text-shadow: 3px 3px @category-item-shadow;
            padding: @padding-mobile;
            cursor: pointer;
        }

        &:before {
            content: attr(data-date);
            background-color: @bgColor;
            cursor: pointer;
            background-image: url(../images/calendar/cover.png);
            background-repeat: no-repeat;
            background-position: center bottom;
            transition: transform .35s ease-out;
            font-size: 3.8rem;
            z-index: 2;
        }

        &:after {
            content: attr(data-weekday);
            font-size: 3.4rem;
            padding-top: @padding-mobile * 4;
            background-image: url(../images/calendar/open.svg);
            background-repeat: no-repeat;
            background-position: center bottom;
            background-size: 100% auto;
            z-index: 3;
        }

        .actions {
            display: none;
        }

        .labels {
            visibility: hidden;
        }

        &.active {
            outline: 10px solid @red;
        }

        &.special {
            border-color: @bgSpecialColor;
            background-color: @bgSpecialColor;

            &:before {
                background-color: @bgSpecialColor;
                // background-image: url(https://sklep.tiptopol.pl/media/wysiwyg/kalendarz/cover-special.png);
                background-image: url(../images/calendar/cover-special.png);
            }

            &:after {
                background-image: url(../images/calendar/open-special.svg);
                background-position: initial;
            }

            &.active {
                border-color: @red;
            }

            * {
                visibility: hidden;
                pointer-events: none;
            }

            .item-content,
            .item-title,
            .item-description {
                opacity: 0;
                transition: opacity @transition-short;
            }

            &.visible {
                border-color: @bgSpecialColor;

                .item-content,
                .item-title,
                .item-description {
                    visibility: visible;
                    opacity: 1;
                }
            }

            .item-content {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
            }
        }

        &.visible {
            border-color: @red;

            &:before,
            &:after {
                content: none;
            }

            .labels {
                visibility: visible;
            }
        }

        &.animation-on {
            overflow: hidden;

            .labels {
                visibility: hidden;
            }

            &:before {
                transform: translate(0, -100%);
                pointer-events: none;
                content: '';
            }

            &:after {
                content: none;
            }
        }
    }

    .item-content {
        color: @white;
        padding: @padding-mobile;
        color: @white;
    }

    .item-title,
    .item-description {
        font-weight: 700;
    }

    .item-title {
        font-size: 4rem;
        line-height: 1.1;
    }
}